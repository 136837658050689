import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { css } from '@emotion/react';

export const PrimaryCustomButton = styled(Button)(({ theme ,extraStyle}) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '1.25rem',
  borderRadius: '0.5rem',
  textTransform:'capitalize',
  '&:hover': {
    background: theme.palette.primary.main,
    opacity: 0.75,
  },
  '&:disabled': {
    background: '#C5C5C5',
    color: theme.palette.primary.contrastText,
  },
  ...(extraStyle ? css`${extraStyle}` : {}),
}));

export const SecondaryCustomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  background: theme.palette.secondary.main,
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '1.25rem',
  borderRadius: '0.5rem',
  '&:hover': {
    opacity: 0.7,
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
}));
