// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mySwiperCareer .swiper {
    width: 100%;
    height: 100%;
  }
  
  .mySwiperCareer .swiper-slide {
    /* text-align: center;
    font-size: 18px;
    background: #fff; */
  
    /* Center slide text vertically */
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  .mySwiperCareer .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/careers/careerslider/careerslider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;EACd;;EAEA;IACE;;uBAEmB;;IAEnB,iCAAiC;IACjC;;0BAEsB;EACxB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".mySwiperCareer .swiper {\n    width: 100%;\n    height: 100%;\n  }\n  \n  .mySwiperCareer .swiper-slide {\n    /* text-align: center;\n    font-size: 18px;\n    background: #fff; */\n  \n    /* Center slide text vertically */\n    /* display: flex;\n    justify-content: center;\n    align-items: center; */\n  }\n  \n  .mySwiperCareer .swiper-slide img {\n    display: block;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
