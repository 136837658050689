export const stepHead = {
    fontWeight:'400',
    textAlign:'center',
    padding:'0 0 50px 0',
    '@media (max-width:768px)':{
        padding:'0 0 25px 0',
        fontSize:'30px',
    },
    '@media (max-width:408px)':{
        padding:'0 0 25px 0',
        fontSize:'22px',
    }
}

export const blueLine = {
    position:'absolute',
    right:'10%',

    img:{
        maxHeight: '700px',
    }
    
}