import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const LandingClientsTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Dystopian',
    color: theme?.palette?.lightWhiteColor,
    textAlign:'center',
    fontSize: '80px',
    fontWeight: 700,
    // [theme.breakpoints.down('lg')]: {
    //     fontSize: '70px', // Font size for large screens
    // },
    [theme.breakpoints.down('md')]: {
        fontSize: '60px', // Font size for medium screens
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '40px', // Font size for small screens
    },
    // [theme.breakpoints.up('xs')]: {
    //     fontSize: '40px', // Font size for extra small screens
    // },
}))

