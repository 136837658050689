import React, { useState, useEffect, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { routes } from './routes'
import Landing from './pages/landing'
import Aboutus from './pages/aboutus'
import Works from './pages/works'
import Ourteam from './pages/ourteam'
import Careers from './pages/careers'
import Contactus from './pages/contactus'
import LoadingComponent from './component/UI-KIT/loading'
import Header from './component/UI-KIT/main-header'
import Footer from './component/UI-KIT/footer'
import CareersDetails from './pages/careers/careers_details'

function App() {
    
    const [isLoading, setIsLoading] = useState(() => {
        // Check if loading has already been shown in this session
        return sessionStorage.getItem('hasLoaded') !== 'true';
    });

    useEffect(() => {
        if (isLoading) {
            const timer = setTimeout(() => {
                setIsLoading(false);
                // Mark as loaded in sessionStorage
                sessionStorage.setItem('hasLoaded', 'true');
            }, 3500); // Adjust duration to match your loading animation
            return () => clearTimeout(timer);
        }
    }, [isLoading]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading... </div>}>
                <Header />
                <Routes>
                    <Route path={routes.LANDING} element={<Landing />} />
                    <Route path={routes.DASHBOARD} element={<Landing />} />
                    <Route path={routes.ABOUTUS} element={<Aboutus />} />
                    <Route path={routes.WORKS} element={<Works />} />
                    <Route path={routes.OUR_TEAM} element={<Ourteam />} />
                    <Route path={routes.CAREERS} element={<Careers />} />
                    <Route
                        path={routes.CAREERS_DETAILS}
                        element={<CareersDetails />}
                    />
                    <Route path={routes.CONTACTUS} element={<Contactus />} />
                </Routes>
                <Footer />
            </Suspense>
        </BrowserRouter>
    )
}

export default App
