import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'


export const DnaHeading = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(32px, 4vw, 64px)',
    color: '#fff',
    fontFamily:'Dystopian',
    lineHeight: 'clamp(38.4px, 4vw, 78.4px)',
    fontWeight: 700,
    letterSpacing: '1px',
    
}))
export const DnaSubHeading = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(14px, 4vw, 20px)',
    color: '#fff',
    fontFamily:'Dystopian',
    lineHeight: 'clamp(12px, 4vw, 24px)',
    fontWeight: 700,
    letterSpacing: '1px',
    color:'#FEC61D',
    paddingBottom:'24px'
    
}))
export const DnaSubHeading1 = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(14px, 4vw, 20px)',
    color: '#fff',
    fontFamily:'Dystopian',
    lineHeight: 'clamp(12px, 4vw, 24px)',
    fontWeight: 700,
    letterSpacing: '1px',
    color:'#FE6933',
    paddingBottom:'24px'
    
}))

export const DnaContent = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(0.875rem, 2.5vw, 1.1rem)',
    color: '#fff',
    fontFamily:'lufga',
    lineHeight: 'clamp(1.25rem, 2.5vw, 2rem)',
    fontWeight: 500,
    // letterSpacing: '1px',

    
}))

export const OurvaluesHeading = styled(Typography)(({ theme }) => ({
    fontSize: 'unset',
    color: '#fff',
    fontFamily:'Dystopian',
    lineHeight: 'unset',
    fontWeight: 700,
    // letterSpacing: '1px',
    color:'#fff',
    paddingBottom:'16px',

    [theme.breakpoints.up('lg')]: {
        fontSize: 'clamp(14px, 4vw, 18px)',
        lineHeight: 'clamp(12px, 4vw, 21.6px)',
      },
    
}))

export const OurvaluesContent = styled(Typography)(({ theme }) => ({
    fontSize: 'unset',
    color: '#fff',
    fontFamily:'lufga',
    lineHeight: 'unset',
    fontWeight: 300,
    
    [theme.breakpoints.up('md')]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
    },

    [theme.breakpoints.up('lg')]: {
        fontSize: 'clamp(0.875rem, 2.5vw, 1rem)',
        lineHeight: 'clamp(1rem, 2vw, 1.75rem)',
    },
    
}))