// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(45deg, #703EB6, #43217E, #230F4F);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
 
.logo svg {
    width: 150px;
    height: 150px;
    animation: draw 28s ease;
    stroke-dasharray: 4500;
    stroke: #fff;
    stroke-width: 0.5;
    fill-opacity: 0;
}
 
.logo h4 {
    color: #fff;
    font-size: 32px;
    letter-spacing: 4px;
    font-family: 'Dystopian';
    font-weight: 200;
}
 
@keyframes draw {
    0% {
        stroke-dashoffset: 4500;
    }
    100% {
        stroke-dashoffset: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/UI-KIT/loading/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,6DAA6D;IAC7D,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,wBAAwB;IACxB,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".logo{\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 10;\n    background: linear-gradient(45deg, #703EB6, #43217E, #230F4F);\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n \n.logo svg {\n    width: 150px;\n    height: 150px;\n    animation: draw 28s ease;\n    stroke-dasharray: 4500;\n    stroke: #fff;\n    stroke-width: 0.5;\n    fill-opacity: 0;\n}\n \n.logo h4 {\n    color: #fff;\n    font-size: 32px;\n    letter-spacing: 4px;\n    font-family: 'Dystopian';\n    font-weight: 200;\n}\n \n@keyframes draw {\n    0% {\n        stroke-dashoffset: 4500;\n    }\n    100% {\n        stroke-dashoffset: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
