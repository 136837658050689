import React from 'react'
import {
    Grid,
    Box,
    Typography,
    Item,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Images } from '../../asset/images'
import './style.css'
import {
    WorkHeading,
    WorkHeaderColor,
    WorkHelloDreamer,
    WorkHelloDreamerPtag,
} from './work.style'
import { useState, useRef } from 'react'
import Website from './website'

export default function Works() {
    //down scroll
    const scrollToSectionRef = useRef(null)

    const handleScroll = () => {
        if (scrollToSectionRef.current) {
            // Get the top position of the element relative to the document
            const elementPosition =
                scrollToSectionRef.current.getBoundingClientRect().top +
                document.documentElement.scrollTop

            // Adjust the offset as needed (e.g., 100px above the section)
            const offset = -200 // Adjust this value to where you want the scroll to stop

            // Scroll to the calculated position
            window.scrollTo({
                top: elementPosition - offset,
                behavior: 'smooth',
            })
        }
    }

    const theme = useTheme()
    const isLargeScreen = useMediaQuery('(min-width: 1235px)')
    const isMediumScreen = useMediaQuery('(max-width: 1235px)')

    const isLargeScreen2 = useMediaQuery('(min-width: 521px)')
    const isMediumScreen2 = useMediaQuery('(max-width: 521px)')
    console.log(isLargeScreen2, isMediumScreen2)

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid
                    container
                    rowSpacing={1}

                >
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ position: 'relative' }}>
                            <img
                                src={Images.WorkHeroImg}
                                className="bannerImg"
                                alt="banner"
                                style={{ width: '100%' }}
                            />
                           <Box
                                        className="bannercontent container1 container2"
                                        sx={{
                                            position: 'absolute',
                                            top: 'clamp(83px,4vw,166px)',
                                            left: '0',
                                            right: '0',
                                            color: 'white',
                                            padding:'10.375rem 0rem 0 ',
                                            maxWidth:'1296px',
                                            margin:'0 auto'
                                        }}
                                    >
                                                <WorkHeading variant="h1">
                                                    A Process — with
                                                </WorkHeading>
                                                <WorkHeading variant="h1">
                                                    You in Mind
                                                </WorkHeading>
                                                {isLargeScreen2 && (
                                                    <WorkHeaderColor variant="body2">
                                                        We craft websites with your needs in
                                                        mind. Our process ensures
                                                        <br /> smooth execution, delivering a
                                                        site that perfectly matches your
                                                        <br /> vision.
                                                    </WorkHeaderColor>
                                                )}

                                                {isMediumScreen2 && (
                                                    <WorkHeaderColor variant="body2">
                                                        We craft websites with your needs in
                                                        mind. Our process ensures smooth
                                                        execution, delivering a site that
                                                        perfectly matches your vision.
                                                    </WorkHeaderColor>
                                                )}
                                </Box>

                            <Box
                                className="bannerComputer"
                                sx={{
                                    width: '100%',
                                    // position: 'absolute',
                                    // top: '62%',
                                    transform:'translateY(-367px)',
                                    height:'0px',
                                    textAlign: 'center',
                                }}
                            >
                                <Grid
                                    container
                                    
                                    rowSpacing={1}
                                    sx={{
                                        padding: {
                                            xs: '0 1.25rem',
                                            sm: '0 2rem',
                                            md: '0 2rem',
                                            lg: '0',
                                        },
                                        maxWidth: '1296px',
                                        margin: '0 auto'
                                    }}
                                    className="imagecompPad container1 container2"
                                >
                                    <Grid item xs={12} sm={12} md={12} >
                                        <img
                                            src={Images.WorkCompImg}
                                            alt="ComputerImg"
                                            style={{ width: '100%' }}
                                        />
                                        <div
                                            id="downArrow"
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '17%',
                                                width: '156px',
                                                height: '100px',
                                                //  zIndex:'10',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleScroll}
                                        ></div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ width: '100%', backgroundColor: '#000', }}>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                        className="container1 container2"
                            sx={{

                                padding: {
                                    xs: '0 1.25rem',
                                    sm: '0 2rem',
                                    md: '0 4.5rem',
                                    lg: '0',

                                },
                                maxWidth: '1296px', margin: '0 auto'
                            }}
                        >
                            <WorkHelloDreamer
                                className="dreamerCont"
                                variant="h1"
                                ref={scrollToSectionRef}
                            >
                                Hello Trailblazers
                            </WorkHelloDreamer>
                            {isLargeScreen && (
                                <WorkHelloDreamerPtag variant="body2">
                                    We’re a globally recognized design
                                    consultancy, offering strategic insights and
                                    solutions. Our team blends brilliant
                                    minds—thinkers, strategists, designers,
                                    researchers, developers, and organizers.
                                    Perfectly sized, entirely independent, and
                                    dedicated to excellence.
                                </WorkHelloDreamerPtag>
                            )}
                            {isMediumScreen && (
                                <WorkHelloDreamerPtag variant="body2">
                                    We’re a globally recognized design
                                    consultancy, offering strategic insights and
                                    solutions. Our team blends brilliant
                                    minds—thinkers, strategists, designers,
                                    researchers, developers, and organizers.
                                    Perfectly sized, entirely independent, and
                                    dedicated to excellence.
                                </WorkHelloDreamerPtag>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Website />
        </>
    )
}
