import React, { useEffect, useRef } from 'react';
import { Box, Grid, useMediaQuery, useTheme, Typography } from '@mui/material';
import anime from 'animejs/lib/anime.es.js';
import '../../landing/landing-clients/Slider.css'; 
import { sideBars } from '../../../constants';

export default function BarLine() {
    const textRef = useRef(null);
    const theme = useTheme();
    const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (textRef.current) {
            textRef.current.innerHTML = textRef.current.textContent.replace(
                /\S/g,
                "<span class='letter'>$&</span>"
            );

            anime.timeline({
                loop: true,
                delay: 3000, 
            })
            .add({
                targets: textRef.current.querySelectorAll('.letter'),
                translateY: ['1.1em', 0],
                opacity: [0, 1],
                duration: 750,
                easing: 'easeOutExpo',
                delay: (el, i) => 50 * i,
            })
            .add({
                targets: textRef.current,
                opacity: [1, 0],
                duration: 1000,
                easing: 'easeOutExpo',
                delay: 1000, 
            });
        }
    }, []);

    const animatedContent = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 'fit-content',
            }}
        >
            <Typography
                className="ml6"
                sx={{
                    fontSize: { xs: '20px', sm: '26px', md: '48px', lg: '48px' }, // Responsive font size
                    fontWeight: 500,
                    textAlign: 'center',
                    margin: '0 3rem', // Adjust gap between phrases
                    whiteSpace: 'nowrap',
                    fontFamily:'Lufga'

                }}
                ref={textRef}
            >
                Born in Cuddalore, Built for the Borderless Web
            </Typography>
        </Box>
    );

    return (
        <Box
            sx={{
                background: 'rgba(254, 198, 29, 1)',
                padding: { xs: '2rem', sm: '2rem', md: '2rem' },
                width: '100%', // Make the container full width
                position: 'relative',
                left: {
                    md: '-30px',
                    xs: '-15px'
                },
                top: {
                    md: '0',
                    sm: '50px',
                    xs: '50px',
                },
                transform: 'rotate(-4.02deg)',
                marginTop: '-85px',
                marginBottom: '-85px',
                overflow: 'hidden', 
                
            }}
        >
            <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    margin: 0,
                }}
            >
                {isMediumOrSmaller ? (
                    <marquee scrollamount="15" direction="left" behavior="scroll" style={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Box sx={{ display: 'inline-block', width: '100%', padding: '0 3rem' }}>
                                <Typography
                                    className="ml6"
                                    sx={{
                                        fontSize: { xs: '20px', sm: '26px', md: '30px' }, // Responsive font size
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap',
                                        fontFamily:'Lufga'
                                    }}
                                >
                                    Born in Cuddalore, Built for the Borderless Web
                                </Typography>
                            </Box>
                        </Box>
                    </marquee>
                ) : (
                    animatedContent
                )}
            </Grid>
        </Box>
    );
}
