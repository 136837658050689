export const buttonLeft ={
  marginLeft:'10px',
  border:'1px solid rgba(254, 198, 29, 1)',
    color: '#fff',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(254, 198, 29, 1)',
    },
}
export const buttonRight ={
    marginRight: '10px',
    border:'1px solid rgba(254, 198, 29, 1)',
    color: '#fff',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(254, 198, 29, 1)',
    },
    
}

export const btnContainer = {
  position: 'absolute',
  top: '50%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  transform: 'translateY(-50%)',
  // px: 2,
  zIndex: 10,
  '@media (max-width:600px)':{
    display:'none'
  },
  // padding:'0 4.5rem',

}

export const tabStyle = {
  '@media (max-width:500px)':{
     padding:'10px 5px',
  }
}