import styled from '@emotion/styled'
import { Box, Button, Typography } from '@mui/material'



export const contactErrorStyle = {

    color:'red',
    fontFamily:'lufga',
    fontSize:'14px',
    mt:1,



}

export const styleModel = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {sm:'400px',xs:'200px'},
    backgroundColor:'#FFFFFF',
    borderRadius:'20px',
    boxShadow: 24,
    p: {sm:4,xs:2},
    textAlign:'center'
  };
 

export const contactLeft = {
    width:'534px',
    border: '2px solid #3EC5FF',
    // height:'400px',
    boxSizing:'border-box',
    borderRadius:'30px',
    fontFamily:'Lufga',
    '@media (max-width:992px)':{
        maxWidth:'100%',
    },
    padding:'0 48px',
    '@media (max-width:768px)':{
        padding:'0 20px',
    }
}

export const contactRight = {
    maxWidth:'728px',
    backgroundColor:'rgba(255, 255, 255, 1)',
    borderRadius:'30px',
    '@media (max-width:600px)':{
        width:'100%',
    }

}

export const contactStyle = {
    margin:'0 auto',
    maxWidth:'1296px',
    // height:'600px',
    color:'#fff',
    padding:{md:'150px 4.5rem 0 4.5rem',xs:'150px 20px 0 20px',},
    display:'flex',
    justifyContent:'space-between',
    position:'relative',
    gap:'34px',     
    '@media (max-width:992px)':{
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
    },
    zIndex:'3'
}


export const contactRightInner = {

    maxWidth:'628px',
    margin:'0 auto', 
    fontFamily:'Lufga',  
    padding: '56px 50px 25px 50px',
    '@media (max-width:1100px)':{
        padding:'56px 25px 100px 25px',
    },
    'label':{
        fontFamily:'Lufga',
        fontSize:'14px',
        color:'#000'
    }
}




export const ContactTeam = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    padding:'10px 15px',


}))
export const ContactStatus = styled(Box)(({ theme }) => ({
    display:'flex',
    padding:'12px 0',
    alignItems:'center',
}))

export const ContactRightHead = {
    color:'#000',
    fontFamily:'Dystopian',
    fontSize:'48px',
    lineHeight:'75px',
    fontWeight:'700',
//    textAlign:'center',
//    padding:'0 17px',
   '@media (max-width:768px)':{
    fontSize:'36px',
    padding:'0'
   },
   '@media (max-width:600px)':{
    fontSize:'24px',
    lineHeight:'30px',
   }
}
export const ContactText = {
    // maxWidth:'289px',
    width:'100%',
    

}

export const contactField = {
    fontFamily:'Lufga',
    padding:'25px 0 25px 0',
    display:'flex',
    justifyContent:'space-between',
    gap:'15px',
    marginBottom:'56px',

    '@media (max-width:600px)':{
       flexDirection:'column',
       marginBottom:'0'
    }
    
}

export const contactBtn = {
    padding:'48px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:600px)':{
        flexDirection:'column',
        alignItems:'center',
        gap:'15px',
    }
}
export const contactButton = {
    display: 'flex',
    // alignItems:'end',
    
    
    contactFirst: {
        width: '60px',
        backgroundColor:'rgba(7, 232, 238, 1)',
        borderRadius:'16px',
        '@media (max-width:768px)':{
            width:'40px',
        }
        
    },
    contactSecond: {
        fontWeight:'400',
        fontFamily:'Lufga',
        textTransform:'capitalize', 
        width: '166px',
        backgroundColor:'rgba(7, 232, 238, 1)',
        borderRadius:'16px',
        '@media (max-width:768px)':{
            width:'140px',
        }
        
    },
}

export const ContactsInfo = {
    display:'flex',alignItems:'center',
    paddingTop:'16px',
    'img':{
        paddingRight:'12px',
    },
    'a':{
        textDecoration:'none',
        color:'#fff',
    }
}

export const ContactBlurBlue = {
    position:'absolute',
}

export const ConstInput = {
    width: '100%',
        '& .MuiInput-underline:before': {
          borderBottomColor: 'gba(127, 131, 141, 0.2)', 
        },
        '& .MuiInputBase-input': {
            fontFamily: 'Lufga',
          },
}

export const blueGradient = {
    background: `
    radial-gradient(
      circle at top,
      rgba(8, 0, 139, 1) 0%,
      rgba(7, 7, 7, 1) 57%
    )
  `, 
  '@media (max-width:992px)': {
          background: `
    radial-gradient(
      circle at top,
      rgba(8, 0, 139, 1) 0%,
      rgba(7, 7, 7, 1) 40%
    )
  `}
}

export const greenGradient = {

    background: `
    radial-gradient(
      circle at 70% 33%,
      rgba(44, 254, 163, 0.5) 0%,
      rgba(44, 254, 163, 0) 52%
    )
  `,
   '@media (max-width:992px)': {
            background: `
    radial-gradient(
      circle at 51% 75%,
      rgba(44, 254, 163, 0.5) 0%,
      rgba(44, 254, 163, 0) 43%
    )
  `}
}

