import React from 'react'

import {  cuddaloreSEO } from '../../TeamInfo'
import { DoubleCircle, PurpleBlurBig, Team, TeamBox, TeamCardBox, TeamDescription, TeamHead, TeamOut } from '../../teamgroupStyle'
import TeamCard from '../../teamCards'
import star from '../../../../../asset/images/doublecircle.png'
import blur from '../../../../../asset/images/purpleblurbig.png'
import { Images } from '../../../../../asset/images'

function CudSEO() {
  return (
   <>
    <TeamOut>
    <DoubleCircle>
          <img src={Images.Doublecircle} style={{width:'100%'}}/>
        </DoubleCircle>
        <PurpleBlurBig>
          <img src={Images.Purpleblurbig} style={{width:'100%'}}/>
        </PurpleBlurBig>
    <Team>
        <TeamBox>
            <TeamHead>
            Visibility Virtuosos
            </TeamHead>
            <TeamDescription>
            Meet our Visibility Virtuosos, the SEO Executives at House of Websites. They masterfully optimize strategies to enhance search rankings and drive organic growth.
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {cuddaloreSEO.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
    </TeamOut>
   </>
  )
}

export default CudSEO
