import styled from '@emotion/styled';


export const TextGrayTitle = styled("h4")(({ theme }) => ({
    fontFamily: 'Dystopian',
    color: '#000',
    fontSize: "64px",
    marginTop: "10px",
    marginBottom: '30px',
    letterSpacing: '2px',
    // width: "30%",
    
    [theme.breakpoints.down('md')]: {
        fontSize: '45px', // Tablet
        marginTop: '10px',
        marginBottom: '30px',
        // width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '30px', // Mobile
        marginTop: '15px',
        marginBottom: '15px',
        // width: '65%',
        textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: "38px",
        marginTop: '20px',
        marginBottom: '20px',
        // width: '65%',
        textAlign: 'center'
       
    },
}));

export const TextGrayColor = styled("h4")(({ theme }) => ({
    // display: 'inline',
    margin: 0,
//   background: 'linear-gradient(80.32deg, #F83A3A 10%, #F13DD4 50%, #F83A3A 80%)',
background: 'linear-gradient(90deg, rgba(248, 58, 58, 1) 0%, rgba(241, 61, 212, 1) 50%, rgba(248, 58, 58, 1) 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
//   textAlign: "right",
//   display: "flex",
//   justifyContent: "flex-end"
marginLeft: '155px'
  
}))