import React from 'react'
import { Box, Typography } from '@mui/material'
import { Images } from '../../asset/images'
import { StyledVideo } from './landing.style'

const LandingVideo = () => {
    return (
        <Box
            sx={{
                background: 'rgba(15, 1, 33, 1)',
                // maxWidth:'1296px',
                // margin:'0 auto'
            }}
        >
            <Box
                sx={{
                    margin: 'auto',
                    maxWidth: '1296px',
                    padding: {
                        lg: '0 11rem',
                        md: '0 11rem',
                        sm: '0 5rem',
                        xs: '0 2rem',
                    },
                }}
            >
                <StyledVideo
                    src={Images?.HOW_VIDEO}
                    autoPlay
                    muted
                    loop
                  
                >
                    Your browser does not support the video tag.
                </StyledVideo>
            </Box>
        </Box>
    )
}

export default LandingVideo
