import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Progress(props) {
  return (
    <>
                <Backdrop
                  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                  open={props.openBack}
                  
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
    </>
  )
}

export default Progress
