import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Grid'
import { Images } from '../../../asset/images'
import '../style.css'
import {
    SelectedworkHeading,
    SelectedworkPtag,
    SelectedworkPtagRight,
    SelectedworkPtagRightHastag,
    TechStack,
} from '../work.style'
// import { useState, useRef } from 'react'
import Contact from '../contact-us'

export default function Works() {
    // const [hoverStates, setHoverStates] = useState({
    //     element1: false,
    //     element2: false,
    //     element3: false,
    //     element4: false,
    //     element5: false,
    //     element6: false,
    // });
    // const handleMouseEnter = (element) => {
    //     setHoverStates((prevStates) => ({ ...prevStates, [element]: true }));
    // };

    // const handleMouseLeave = (element) => {
    //     setHoverStates((prevStates) => ({ ...prevStates, [element]: false }));
    // };
    return (
        <>
            <Box
                sx={{
                    backgroundColor: '#F7F7F7',
                    width: '100%',
                    paddingBottom: '6.051rem',
                }}
            >
                <Box
                    className="container1"
                    sx={{ maxWidth: '1296px', margin: '0 auto' }}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            order={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '1rem 1.25rem',
                                        sm: '1rem 2rem',
                                        md: '4.063rem 0rem 0 ',
                                        lg: '4.063rem 0 0 0',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    Selected work!
                                </SelectedworkHeading>
                                <SelectedworkPtag
                                    variant="body2"
                                    sx={{ maxWidth: { md: '514px' }, 
                                    paddingBottom:{
                                        lg:'78.5px',
                                        sm:'32px',
                                        xs:'24px'
                                    } }}
                                >
                                    We cherish our collaborations with amazing
                                    companies and are proud of our shared
                                    achievements.
                                </SelectedworkPtag>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container 
                    columnSpacing={1}
                    sx={{
                        
                        rowGap: {
                            xs: 4,    
                            sm: 4,    
                            md: 8,   
                            lg: 13, 
                            },
                        }}
                        >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                //  onMouseEnter={() => handleMouseEnter("element1")}
                                //     onMouseLeave={() => handleMouseLeave("element1")}
                            >
                                <img
                                    src={Images.WebsiteDesign2}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element1 && */}
                                {/* <Box className="arrowimg">
                                        <img
                                            src={Images.SideArrow}
                                            alt="selectwork"
                                            style={{ width: '93.23px' }}
                                        />
                                    </Box> */}
                                {/* } */}
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 0 0 3.125rem',
                                        lg: '0 0 0 3.125rem',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    New concept properties
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    We designed a dynamic portal for New Concept
                                    Properties, emphasizing their proficiency in
                                    real estate brokerage and property
                                    management. Our design effectively showcases
                                    their diverse services and commitment to
                                    exceptional client service, reflecting their
                                    proactive approach and industry-leading
                                    resources.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Property Portal
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Intuitive
                                            </SelectedworkPtagRightHastag>
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Responsive
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.NCP_Tech}
                                            width={'100%'}
                                            alt="NCP"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Application Design*/}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 4, sm: 4, md: 3, lg: 3 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 3.125rem 0 0',
                                        lg: '0 3.125rem 0 0',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    Neuranest
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    We created a compelling website for
                                    NEURANEST AI, highlighting their expertise
                                    in using technology and data to propel brand
                                    growth. Our design effectively communicates
                                    their strategic approach and commitment to
                                    scaling brands to new heights and achieving
                                    lasting success.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Sleek
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Smart
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Secure
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.Wordpress_Techstack}
                                            width={'100%'}
                                            alt="Neuranest"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 3, sm: 3, md: 4, lg: 4 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem ',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                //  onMouseEnter={() => handleMouseEnter("element2")}
                                //     onMouseLeave={() => handleMouseLeave("element2")}
                            >
                                <img
                                    src={Images.ApplicationDesign}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element2 && */}
                                {/* <Box className="arrowimg_app">
                                    <img
                                        src={Images.SideArrow}
                                        alt="selectwork"
                                        style={{ width: '93.23px' }}
                                    />
                                </Box> */}
                                {/* } */}
                            </Box>
                        </Grid>

                        {/* Branding Identity*/}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 5, sm: 5, md: 5, lg: 5 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                // onMouseEnter={() => handleMouseEnter("element3")}
                                //     onMouseLeave={() => handleMouseLeave("element3")}
                            >
                                <img
                                    src={Images.Identity}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element3 && */}
                                {/* <Box className="arrowimg">
                                    <img
                                        src={Images.SideArrow}
                                        alt="selectwork"
                                        style={{ width: '93.23px' }}
                                    />
                                </Box> */}
                                {/* } */}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 0 3.125rem',
                                        lg: '0 0 0 3.125rem',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    Mycelium
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    We developed an intuitive website for
                                    Mycelium Data Technologies, highlighting
                                    their expertise in sustainability, data
                                    analytics, and cloud solutions. Our design
                                    seamlessly integrates their services, from
                                    carbon footprint assessments to ESG
                                    management, enhancing their digital presence
                                    and showcasing their commitment to
                                    innovative, eco-friendly solutions.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Branding
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Custom
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Responsive
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.Wordpress_Techstack}
                                            width={'100%'}
                                            alt="Mycelium"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Packaging Design*/}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 8, sm: 8, md: 7, lg: 7 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 3.125rem 0 0',
                                        lg: '0 3.125rem 0 0',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    Ck's Bakery
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    For CK's Bakery, we created a captivating
                                    website that highlights their tasty &
                                    soulful offerings. Our design showcases
                                    their mouth watering products and warm
                                    atmosphere, reinforcing their reputation as
                                    a beloved bakery & enhancing their online
                                    presence.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Modern
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Fluid
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Sophisticated
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.CK_Tech}
                                            width={'100%'}
                                            alt="CK"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 7, sm: 7, md: 8, lg: 8 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                //  onMouseEnter={() => handleMouseEnter("element4")}
                                //     onMouseLeave={() => handleMouseLeave("element4")}
                            >
                                <img
                                    src={Images.WebsiteDesign}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element4 && */}
                                {/* <Box className="arrowimg_app">
                                    <img
                                        src={Images.SideArrow}
                                        alt="selectwork"
                                        style={{ width: '93.23px' }}
                                    />
                                </Box> */}
                                {/* } */}
                            </Box>
                        </Grid>

                        {/* Website design*/}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 9, sm: 9, md: 9, lg: 9 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                // onMouseEnter={() => handleMouseEnter("element5")}
                                //     onMouseLeave={() => handleMouseLeave("element5")}
                            >
                                <img
                                    src={Images.SelectWork}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element5 && */}
                                {/* <Box className="arrowimg">
                                    <img
                                        src={Images.SideArrow}
                                        alt="selectwork"
                                        style={{ width: '93.23px' }}
                                    />
                                </Box> */}
                                {/* } */}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 10, sm: 10, md: 10, lg: 10 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 0 0 3.125rem',
                                        lg: '0 0 0 3.125rem',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    Jangoz
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    We elevated Jango'Z with a dynamic,
                                    user-friendly website that highlights their
                                    vibrant Mexican theme and menu. Our design
                                    enhanced their digital presence, showcasing
                                    their commitment to quality & setting them
                                    apart as Perambur's top-rated restaurant.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Innovative
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Sleek
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                User-Friendly
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.Jangoz_Tech}
                                            width={'100%'}
                                            alt="Jangoz"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Website2 Design*/}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 12, sm: 12, md: 11, lg: 11 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 3.125rem 0 0',
                                        lg: '0 3.125rem 0 0',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    kailasa kaladhara
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    We crafted a sophisticated website for
                                    Kailasa Kaladhara, showcasing the timeless
                                    beauty of Bharatanatyam, Kathak, Kuchipudi,
                                    Martial Arts, & Yoga. Our design elegantly
                                    reflects their cultural heritage, engaging
                                    visitors & celebrating the art of tradition.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Dynamic
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Engaging
                                            </SelectedworkPtagRightHastag>
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Responsive
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.Wordpress_Techstack}
                                            width={'100%'}
                                            alt="kailasa"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 11, sm: 11, md: 12, lg: 12 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                // onMouseEnter={() => handleMouseEnter("element6")}
                                //     onMouseLeave={() => handleMouseLeave("element6")}
                            >
                                <img
                                    src={Images.PackingDesign}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element6 &&
                                <Box className="arrowimg_app"  >
                                    <img
                                        src={Images.SideArrow}
                                        alt="selectwork"
                                        style={{ width: '93.23px' }}
                                    />
                                </Box>
                            } */}
                            </Box>
                        </Grid>

                        {/* Website design3*/}

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 13, sm: 13, md: 13, lg: 13 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0',
                                        lg: '0',
                                    },
                                }}
                                // onMouseEnter={() => handleMouseEnter("element5")}
                                //     onMouseLeave={() => handleMouseLeave("element5")}
                            >
                                <img
                                    src={Images.Samasta}
                                    alt="selectwork"
                                    style={{ width: '100%' }}
                                />
                                {/* {hoverStates.element5 && */}
                                {/* <Box className="arrowimg">
                                    <img
                                        src={Images.SideArrow}
                                        alt="selectwork"
                                        style={{ width: '93.23px' }}
                                    />
                                </Box> */}
                                {/* } */}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            order={{ xs: 14, sm: 14, md: 14, lg: 14 }}
                            sx={{
                                display: { md: 'flex' },
                                alignItems: { md: 'center' },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: {
                                        xs: '0 1.25rem',
                                        sm: '0 2rem',
                                        md: '0 0 0 3.125rem',
                                        lg: '0 0 0 3.125rem',
                                    },
                                }}
                            >
                                <SelectedworkHeading
                                    variant="h1"
                                    sx={{
                                        paddingTop: { md: '0px !important' },
                                    }}
                                >
                                    Samasta
                                </SelectedworkHeading>
                                <SelectedworkPtagRight variant="body2">
                                    We designed a captivating Shopify storefront
                                    for Samasta, delivering a seamless shopping
                                    experience for their exquisite clothing &
                                    saris. Our approach highlights the unique
                                    synergy between Mridulika Menon Madiraju &
                                    Arundhati Menon, ensuring a smooth &
                                    enjoyable purchase process for their
                                    beautifully crafted creations.
                                </SelectedworkPtagRight>
                                <Box>
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={1}
                                    >
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Custom
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Versatile
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={6} lg={4}>
                                            <SelectedworkPtagRightHastag variant="body2">
                                                Ecommerce
                                            </SelectedworkPtagRightHastag>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    className="techStack_Text"
                                    columnSpacing={1}
                                >
                                    <Box>
                                        <TechStack variant="body2">
                                            Tech Stack:
                                        </TechStack>
                                    </Box>
                                    <Box>
                                        <img
                                            className="techStack"
                                            src={Images.Shopify_logo}
                                            width={'100%'}
                                            alt="samasta"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Contact />
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
