import styled from '@emotion/styled'
import { Accordion, Box, Button, TextField, Typography } from '@mui/material'

export const NewTechnologyTitle = styled(Typography)(({ theme }) => ({
    fontSize: '40px',
    fontWeight: '700',
    textAlign: 'center',
    background:
        'linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    // [theme.breakpoints.down('lg')]: {
    //     fontSize: '60px',
    //     padding: '0 2.5rem',
    // },
    [theme.breakpoints.down('md')]: {
        fontSize: '25px',
        padding: '0 1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '23px',
        padding: '0 1rem',
    },
}))

export const NewTechnologyEmail = styled(Typography)(({ theme }) => ({
    color: theme?.palette.lightGray,
    fontWeight: 400,
    fontFamily: 'Lufga',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        textAlign: 'center',
    },
}))

export const NewTechnologyTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        // color: 'rgba(255, 255, 255, 0.32)',
        color: 'white',
        fontFamily: 'Lufga',

        [theme.breakpoints.down('sm')]: {
            padding: '7px 10px 10px 2px',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '30px',
        },
    },
    '& input::placeholder': {
        fontFamily: 'Lufga',
        fontSize: '14px',
    },
}))

export const NewTechnologyForm = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    justifyContent: 'space-between',

    borderRadius: '999px',
    padding: '0 15px 0 5px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '600px',
    [theme.breakpoints.up('sm')]: {
        width: '75%', // Adjust width for small screens and up
    },
    [theme.breakpoints.up('md')]: {
        width: '50%', // Adjust width for medium screens and up
    },
    [theme.breakpoints.up('lg')]: {
        width: '100%', // Adjust width for large screens and up
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px 10px',
    },
}))

export const NewTechnologyButton = styled(Button)(({ theme }) => ({
    marginLeft: '8px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '999px',
    background:
        'radial-gradient(circle, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0))',

    textTransform: 'capitalize',

    fontFamily: 'Lufga',
    padding: '5px 1.5rem',
    color: 'transparent',
    // background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1))',
    // backgroundClip: 'text',
    // WebkitBackgroundClip: 'text',
    // WebkitTextFillColor: 'transparent',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
        padding: '5px 1.5rem',
        fontSize: '11px',
    },
}))

export const BgImage = styled(`img`)(({ theme }) => ({
    height: '100%',
    objectFit: 'contain',
    [theme.breakpoints.up(`md`)]: {
        width: '100%',
    },
}))
