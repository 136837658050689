export const ErrorHandler = (setNameError,setMailError,setInfoError,contactForm)=>{
   
  let check = 0;
  let emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  if(contactForm.fullName.trim()){
    setNameError(false);
    check++;
  }
  else{
    setNameError(true);
  }


  if(!contactForm.emailAddress.trim()){
    setMailError(true)
  }
  else if(!emailTest.test(contactForm.emailAddress)){
    setMailError(true)
  }
  else{
    setMailError(false)
    check++;
  }


  if(contactForm.aboutProject.trim()){
    setInfoError(false)
    check++;
  }
  else{
    setInfoError(true)
  }
  

  return check;

}