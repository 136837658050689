// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  /* Particles.js container */
  #particles-js {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* height: 100%; */
    /* background-color: transparent; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  #particles-js canvas {
    height: 350px!important;
  }
  
  /* Particles canvas */
  .particles-js-canvas-el {
    opacity: 0.3;
  }
  
  .particles-js-canvas-el:hover {
    opacity: 1;
  }



  `, "",{"version":3,"sources":["webpack://./src/pages/landing/particles/Particles.css"],"names":[],"mappings":";;EAEE,2BAA2B;EAC3B;IACE,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,mCAAmC;IACnC,4BAA4B;IAC5B,sBAAsB;IACtB,4BAA4B;EAC9B;;EAEA;IACE,uBAAuB;EACzB;;EAEA,qBAAqB;EACrB;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ","sourcesContent":["\n  \n  /* Particles.js container */\n  #particles-js {\n    position: absolute;\n    width: 100%;\n    top: 0;\n    left: 0;\n    /* height: 100%; */\n    /* background-color: transparent; */\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: 50% 50%;\n  }\n\n  #particles-js canvas {\n    height: 350px!important;\n  }\n  \n  /* Particles canvas */\n  .particles-js-canvas-el {\n    opacity: 0.3;\n  }\n  \n  .particles-js-canvas-el:hover {\n    opacity: 1;\n  }\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
