import styled from '@emotion/styled';


export const GridContainer = styled("div")(({ theme }) => ({
    display: 'grid',
    gridTemplateAreas: `
      'service service service member member project'
      'clients clients location location location project'
    `,
    gap: '24px',    
    transform: 'translateY(-10%)',
    
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',      
      gridTemplateRows: 'repeat(5, 1fr)',
      gridTemplateAreas: `
        'service'
        'member'
        'project'
        'clients'
        'location'
      `,
      transform: 'translateY(-2%)',
    },
    [theme.breakpoints.between(600, 767)]: {
      gridTemplateColumns: '1fr',      
      gridTemplateRows: 'repeat(5, 1fr)',
      gridTemplateAreas: `
         'service'
        'member'
        'project'
        'clients'
        'location'
      `,
      transform: 'translateY(-3%)',
    },
 
    
  }));
  export const ChildItem = styled("div")(({ theme }) => ({
    textAlign: 'center',
  padding: '32px',
  fontSize: '30px',
  borderRadius: '10px',
  [theme.breakpoints.down('lg')]: {
    minHeight: '200px !important',
    padding: '18px',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '170px !important',
    padding: '18px',
  },

  '&.item1': {
    gridArea: 'service',
    minHeight: '300px',
    backgroundColor: '#FEC61D',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
        minWidth: '400px !important',
      },
    [theme.breakpoints.up('md')]: {
      minWidth: '265px !important',
    },
    [theme.breakpoints.between('sm')]: {
      minWidth: '255px !important',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '235px !important',
    },
  },

  '&.item2': {
    gridArea: 'member',
    minHeight: '300px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '&.item3': {
    gridArea: 'project',
    backgroundColor: '#01D648',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '&.item4': {
    gridArea: 'clients',
    minHeight: '300px',
    backgroundColor: '#FE6933',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '&.item5': {
    gridArea: 'location',
    minHeight: '300px',
    backgroundColor: '#00E5FD',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
    
  }));

  export const DashbrdTitle = styled("div")(({ theme }) => ({
    fontSize: '24px',
  fontFamily: 'Dystopian',
  fontWeight: '700',
  color: '#000',

  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
  }));
  export const DashbrdNum = styled("div")(({ theme }) => ({
    fontSize: '70px',
  fontFamily: 'Lufga',
  fontWeight: '300',
  color: '#000',
  maxWidth: '60px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '55px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '42px',
  },
  }));