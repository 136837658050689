
export const bannerStyle = {
    maxWidth:'1400px',
    height:'600px',
    width:'100%',
    // padding: '0px 72px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    margin:'0 auto',
    

}
export const gradientTextStyle = {
    backgroundImage: 'linear-gradient(to right, rgba(248, 58, 58, 1), rgba(241, 61, 212, 1), rgba(248, 58, 58, 1))',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent', 
    fontSize: '70px',
    lineHeihgt:'88px',
    fontWeight: '800',
    fontFamily: 'Dystopian',
    paddingBottom:'20px',
    '@media (max-width:768px)' : {
      fontSize:'44px'
    },
    '@media (max-width:500px)' : {
      fontSize:'28px'
    },
  };

export const abstact = {
    top:'0px',
    right:'0px',
    position: 'absolute',
    height:'250px',
    weight:'250px',
    img: {
        width: '250px',
        height: '250px',
        
        '@media (max-width:1200px)': {
          width: '200px',
          height: '200px',
        },
        '@media (max-width:768px)': {
          width: '170px',
          height: '170px',
        },
        '@media (max-width:500px)': {
          width: '130px',
          height: '130px',
        },
      },
}
export const circle = {
    position: 'absolute',
    left:'0px',
    bottom:'0px',
    img: {
        width:'400px',
        height:'380px',
        
        '@media (max-width:1200px)': {
          width: '320px',
          height: '300px',
        },
        '@media (max-width:768px)': {
          width: '270px',
          height: '260px',
        },
          '@media (max-width:600px)': {
          width: '200px',
          height: '210px',
        },
        '@media (max-width:500px)': {
          width: '130px',
          height: '130px',
        },
      },

}
export const group ={
    position: 'absolute',
    right:'0px',
    bottom:'0px',
    width:'70%',
}
