import React, { useEffect, useRef } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'

const countData = [
    { start: 0, end: 2, suffix: '', projectName: 'Years in Market' },
    { start: 0, end: 50, suffix: '+', projectName: 'Project Done' },
    { start: 0, end: 99, suffix: '%', projectName: 'Top Feedback' },
    { start: 0, end: 100, suffix: '', projectName: 'Cup of Coffee' },
]

function LandingCount() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.5, // Trigger when 50% of the component is in view
    })

    return (
        <Box
            sx={{
                background: '#0F0121',
            }}
        >
            <Grid
                ref={ref}
                container
                spacing={2}
                sx={{
                    padding: {
                        xs: '20px',

                        sm: '2rem',
                        md: '8rem 0 0 0',
                    },
                    maxWidth: '1296px',
                    margin: 'auto',
                    color: 'white',
                    width: {
                        xs: '100%',
                        sm: 'auto',
                    },
                    // flexWrap: 'wrap',
                    // justifyContent: 'space-between',
                    // alignItems: 'center',
                }}
            >
                {countData.map((item, index) => (
                    <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        key={index}
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Syne',
                                    fontSize: {
                                        xs: '60px',
                                        sm: '80px',
                                        md: '100px',
                                    },
                                    color: '#0F0121',
                                    textShadow: `
                                        1px 1px 0 rgba(193, 193, 193, 1), 
                                        -1px -1px 0 rgba(193, 193, 193, 1), 
                                        1px -1px 0 rgba(193, 193, 193, 1), 
                                        -1px 1px 0 rgba(193, 193, 193, 1)
                                    `,
                                    position: 'relative',
                                    lineHeight: '1.3',
                                }}
                            >
                                {inView && (
                                    <CountUp
                                        start={item.start}
                                        end={item.end}
                                        duration={5}
                                        separator=","
                                        formattingFn={(value) =>
                                            value.toString().padStart(2, '0')
                                        }
                                    />
                                )}
                            </Typography>
                            {item.suffix && (
                                <Typography
                                    sx={{
                                        fontFamily: 'Syne',
                                        fontWeight: 500,
                                        fontSize: {
                                            xs: '40px',
                                            sm: '40px',
                                            md: '40px',
                                        },
                                        color: 'rgba(255, 255, 255, 1)',
                                        marginLeft: '4px',
                                        marginTop: '0.5rem',
                                        right: '-35px',
                                        top: '2rem',
                                        position: 'absolute',
                                    }}
                                >
                                    {item.suffix}
                                </Typography>
                            )}
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Lufga',
                                    fontSize: {
                                        xs: '14px',
                                        sm: '18px',
                                        md: '20px',
                                    },
                                    fontWeight: 500,
                                }}
                            >
                                {item.projectName}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box
                sx={{
                    padding: {
                        xs: '20px',
                        sm: '2rem',
                        md: '5rem 4.5rem',
                        lg: '5rem 4.5rem',
                    },
                    maxWidth: '1296px',
                    margin: 'auto',
                }}
            >
                <Divider
                    sx={{
                        backgroundColor: 'rgba(206, 206, 206, 1)',
                        height: '1px',
                    }}
                />
            </Box>
        </Box>
    )
}

export default LandingCount
