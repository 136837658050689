import React from 'react'
import {
    Container,
    Grid,
    Typography,
    Button,
    Link,
    Box,
    Divider,
} from '@mui/material'
import { styled } from '@mui/system'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { Images } from '../../../asset/images'
import { routes } from '../../../routes'
import { useNavigate } from 'react-router-dom'

const ContainerDiv = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
})

const FooterContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#3F17B6',
    color: 'white',
    padding: '0 20px',
    [theme.breakpoints.up('sm')]: { padding: '0 2rem' },
    [theme.breakpoints.up('md')]: { padding: '0 48px' },
    [theme.breakpoints.up('lg')]: { padding: '0 72px' },
}))

const FooterButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: '100px',
    padding: '12px 21px',
    color: 'black',
    fontWeight: 400,
    marginTop: '24px',
    gap: '11px',
    textDecoration: 'none',
    textTransform: 'none',
    [theme.breakpoints.up('xs')]: { fontSize: '14px' },
    [theme.breakpoints.up('sm')]: { fontSize: '16px' },
    [theme.breakpoints.up('md')]: { fontSize: '20px' },
    [theme.breakpoints.up('lg')]: { fontSize: '22px' },
}))

const FooterList = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
})

const FirstGrid = styled(Box)({
    marginTop: '60px',
    maxWidth: '532px',
    '@media (max-width: 600px)': {
        order: '2',
        paddingBottom: '10px',
        margin: '0',
    },
})
const FooterListGrid1 = styled(Grid)({
    '@media (max-width: 600px)': {
        order: '3',
    },
})
const FooterListGrid = styled(Grid)({
    textAlign: 'right',
    lineHeight: '36px',
    fontSize: '20px',
    fontFamily: 'Lufga',
    fontWeight: 300,
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    position: 'relative',
    top: '-250px',
    '@media (max-width: 1200px)': {
        top: '-200px',
    },
    '@media (max-width: 600px)': {
        order: '4',
    },
    '@media (max-width: 980px)': {
        position: 'unset',
    },
})
const DividerLine = styled(Divider)({
    '@media (max-width: 600px)': {
        order: '5',
    },
})
const Rights = styled(Box)({
    '@media (max-width: 600px)': {
        order: '6',
    },
})

const FooterList1 = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    textAlign: 'Right',
    display: 'flex',
    gap: '47px',
    position: 'relative',
    top: '-123px',
    '@media (max-width: 1200px)': {
        gap: '40px',

    },
    '@media (max-width: 900px)': {
        gap: '20px',
    },
    '@media (max-width: 980px)': {
        position: 'unset',
    },
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
        textAlign: 'left',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        // textAlign: 'right',
        gap: '0',
    },
})

const FooterItem = styled('a')({
    lineHeight: '36px',
    fontSize: '20px',
    fontFamily: 'Lufga',
    fontWeight: 300,
    letterSpacing: '1px',
    display: 'flex',
    color:'rgba(255, 255, 255, 1)',
    textDecoration:'none',
    '&:hover': {
        cursor: 'pointer',
    },
})

const FooterLogoImage = styled('img')(({ theme }) => ({
    maxWidth: '80px',
    padding: '20px 0',
    cursor:'pointer',
    [theme.breakpoints.up('sm')]: { maxWidth: '100px' },
    [theme.breakpoints.up('md')]: { maxWidth: '150px' },
    [theme.breakpoints.up('lg')]: { maxWidth: 'unset' },
}))



const Footer = () => {
    const navigate = useNavigate()

   

    return (
        <FooterContainer>
            <ContainerDiv
                sx={{ padding: '0!important', maxWidth: '1296px!important' }}
            >
                <FirstGrid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        paddingTop: '0!important',
                        paddingLeft: '0!important',
                    }}
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            fontSize: {
                                xs: '30px',
                                sm: '50px',
                                md: '60px',
                                lg: '80px',
                            },
                            fontWeight: 700,
                        }}
                    >
                        Want to Start a Project?
                    </Typography>
                    <FooterButton
                        variant="contained"
                        endIcon={<img src={Images.LetsTalk} alt="Arrow" />}
                        component={Link}
                        href={routes.CONTACTUS}
                        sx={{
                            fontSize: '22px',
                            fontFamily: 'Lufga',
                            '&:hover': {
                                background: 'rgba(255, 255, 255, 1)',
                            },
                        }}
                    >
                        Let's Talk
                    </FooterButton>
                </FirstGrid>
                <FooterListGrid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: { xs: '20px', sm: '0!important' } }}
                >
                    Follow Us:
                    <FooterList>
                        <FooterItem href="https://www.linkedin.com/showcase/house-of-websites/?viewAsMember=true"  target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon />
                        </FooterItem>
                    </FooterList>
                </FooterListGrid>

                <Box item xs={12} sm={4} md={5} >
                    <a href={routes?.DASHBOARD}>

                    <FooterLogoImage src={Images.FooterIcon} alt="Logo"  />
                    </a>
                </Box>
                <FooterListGrid1 item xs={12} sm={8} md={7}>
                    <FooterList1>
                        <FooterItem href={routes?.DASHBOARD}>
                            Home
                        </FooterItem>
                        <FooterItem href={routes?.ABOUTUS}>
                            About Us
                        </FooterItem>
                        <FooterItem href={routes?.WORKS}>
                            Works
                        </FooterItem>
                        <FooterItem href={routes?.OUR_TEAM}>
                            Our Team
                        </FooterItem>
                        <FooterItem href={routes?.CAREERS}>Careers</FooterItem>
                        <FooterItem href={routes?.CONTACTUS}>
                            Contact Us
                        </FooterItem>
                    </FooterList1>
                </FooterListGrid1>

                <DividerLine sx={{ backgroundColor: '#7C7C7C' }} />

                <Rights textAlign="center" sx={{ padding: '51px 0 60    px' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '16px',
                            fontFamily: 'Lufga',
                            letterSpacing: '1px',
                        }}
                    >
                        &#169; 2024 houseofwebsites. All rights reserved
                    </Typography>
                </Rights>
            </ContainerDiv>
        </FooterContainer>
    )
}

export default Footer
