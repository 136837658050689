import React from 'react';
import { Images } from '../../../asset/images';
import {
  ProjectCardContainer,
  ProjectImage,
  ProjectInfo,
  ProjectTitle,
  ProjectDescription,
  ProjectOverlay,
  OverlayImage,
} from './projects.style';

const ProjectCard = ({ image, title, description }) => (
  <ProjectCardContainer>
    <ProjectImage src={image} alt={title} />
    <ProjectInfo>
      <ProjectTitle className='project-title'>{title}</ProjectTitle>
      <ProjectDescription>{description}</ProjectDescription>
    </ProjectInfo>
    {/* <ProjectOverlay className="project-overlay">
      <OverlayImage src={Images.ViewProjects} alt="Hover" />
    </ProjectOverlay> */}
  </ProjectCardContainer>
);

export default ProjectCard;
