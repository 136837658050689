import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { Images } from '../../../asset/images'

export const AboutBannerTitle = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    background:
        'linear-gradient(90deg, rgba(248, 58, 58, 1) 0%, rgba(241, 61, 212, 1) 50%, rgba(248, 58, 58, 1) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: 'clamp(30px, 5vw, 80px)',
    fontWeight: '700',
    fontFamily: 'Dystopian',
    marginTop: '10px',
    // letterSpacing: '3px',

    [theme.breakpoints.down('sm')]: {
        padding: '0',
        // fontSize:'38px',
        textAlign: 'center',
    },
}))

export const AboutusBannerTitleStyle = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    fontSize: 'clamp(30px, 5vw, 80px)',
    marginTop: '10px',
    // letterSpacing: '3px',
    fontWeight: '700',
    fontFamily: 'Dystopian',
    color: theme?.palette?.lightWhiteColor,

    [theme.breakpoints.down('sm')]: {
        padding: '0',
        // fontSize:'38px',
        textAlign: 'center',
    },
}))
export const MainBanner = styled(Box)(({ theme }) => ({
    alignItems: 'flex-start',
    gap: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    // [theme.breakpoints.down('xl')]: {
    // padding: '0 4.5rem',
    
    // },

    // [theme.breakpoints.down('lg')]: {
    //     padding: '0 4.5rem',
    // },
  

    [theme.breakpoints.down('md')]: {
        order: 1,
        justifyContent: 'center',
        padding: '0 2rem',

    },

    [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
    },
   
}))

export const AboutisDescription = styled(Typography)(({ theme }) => ({
    fontWeight: 300,
    fontSize: '18px',
    fontFamily: 'Lufga',
    maxWidth: '648px',
    width:'100%',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
}))

export const AboutusBannerMainOrder = styled(Box)(({ theme }) => ({
    color: 'white',
    zIndex: 1,

    // position: 'absolute',
    top: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    // marginTop: '100px',
    width: '100%',
    position: 'relative',

    maxWidth: '1296px',
    margin: 'auto',
    padding: '9rem 4.5rem 4rem 4.5rem',

    // [theme.breakpoints.up('sm')]: {
    //     marginTop: '40px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     marginTop: '150px',
    // },

    [theme.breakpoints.up('lg')]: {
        // background:'pink',
        // padding:'0 4rem',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '4rem 0',
    },
    // [theme.breakpoints.up('xs')]: {
    //     background:'red',
    //     marginTop:''
    // },
}))

// OUR-STORY
export const OurStoryBanner = styled(Box)(({ theme }) => ({
    background: theme?.palette?.lightBlue,
    width: '100%',
}))
export const OurStoryTitle = styled(Typography)(({ theme }) => ({
    fontSize: '64px',
    fontWeight: '700',
    padding: '6rem 0 10rem 0',
    textAlign: 'center',
    color: theme?.palette?.lightWhiteColor,

    [theme.breakpoints.down('md')]: {
        fontSize: '50px',
    padding: '6rem 0 7rem 0',

    },

    [theme.breakpoints.down('sm')]: {
        padding: '4rem 0 5rem 0',
        fontSize: '40px',
    },
}))

export const OurStoryDescription = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 300,
    color: theme?.palette?.lightWhiteColor,
    fontFamily: 'Lufga',
    paddingTop: '6rem',
    maxWidth: '596px',
    [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        paddingTop: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
    },
}))

export const OurStoryDescriptionBanner = styled(Box)(({ theme }) => ({
    padding: '8rem 4rem',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1296px',
    margin: 'auto',
    gap:'2rem',

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: '3rem',
        padding: '8rem 2rem',
        alignItems:'center',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '8rem 1.25rem',
        gap: '1rem',
    },
}))
