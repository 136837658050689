import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowImg, LocationDiv, LocationImg, Form, CurentOpeningBox } from './openings.style'
import { Images } from "../../../asset/images";
import { routes } from "../../../routes";

function CurentopeningsList(props) {

    return (
        <CurentOpeningBox >
            <Box className="text-content">
            <Typography sx={{ fontSize: { xl: "24px", lg: "24px", md: "22px", sm: "20px", xs: "20px" }, fontFamily: "Lufga", fontWeight: "500", mb: "16px" }} >{props.current_p}</Typography>
            <Typography sx={{ fontSize: { xl: "18px", lg: "16px", md: "15px" }, fontFamily: "Lufga", fontWeight: "400", lineHeight: "30px" }}>{props.current_p_cont}</Typography >
            <LocationDiv >
                <LocationImg >

                    <img className="blck-location"
                        src={Images.Location}
                        alt="location"
                    />
                    <img className="white-location"
                        src={Images.LocationWhite}
                        alt="location"
                    />

                    <Typography sx={{ fontSize: { xl: "18px", lg: "18px", md: "17px" }, fontFamily: "Lufga", fontWeight: "400" }}>{props.current_loc_area}</Typography>
                </LocationImg>
                <ArrowImg href={routes?.CAREERS_DETAILS} sx={{ opacity: 0, visibility: "hidden", transition: "opacity 0.3s ease, visibility 0.3s ease", cursor: "pointer" }}>

                    <img src={Images.RightArrow} alt=""   />
                </ArrowImg>
            </LocationDiv>
            </Box>
            <Box className="box-inner"></Box>
        </CurentOpeningBox>
    )
}


export default CurentopeningsList