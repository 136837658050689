import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import './careerslider.css';
import { Autoplay, Navigation } from 'swiper/modules';
import { Box } from '@mui/material';
import { Careerslider } from '../../../constants';
// import { StyledBox } from './careerslider.style';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { btnContainer, custombuttonLeft, custombuttonRight, StyledBox } from './careerslider.style';

function CareerSlider() {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  const handleClick = (index) => {
    swiperRef.current.swiper.slideToLoop(index);
    setActiveIndex(index);
  };

  const handleSlideChange = (swiper) => {
    const index = swiper.realIndex; 
    setActiveIndex(index);
  };

  return (
    <Box sx={{ position: 'relative', maxWidth: '1253px', margin: '0px auto', transform: {lg: 'translatey(-55%)', md: 'translatey(-66%)', sm: 'translatey(-68%)', xs: 'translatey(-30%)'} }} >
      <Swiper
        className="mySwiperCareer"
        ref={swiperRef}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={false}
        navigation={false}
        modules={[Navigation,Autoplay]}
        onSlideChange={handleSlideChange}
         autoplay={{
          delay: 3000, // Delay between slides in milliseconds (3 seconds in this case)
          disableOnInteraction: false, // Continue autoplay after user interactions
        }}
      >
        {Careerslider.map((img, index) => (
          <SwiperSlide key={index}>
            <StyledBox>
              <img
                src={img.slideimg}
                style={{ maxWidth: '100%', height: 'auto' }}
                alt={`Slide ${index + 1}`}
              />
            </StyledBox>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={btnContainer}
      >
        <Box
          onClick={handlePrevClick}
          sx={custombuttonLeft}
        >
          <ChevronLeftIcon />
        </Box>
        <Box
          onClick={handleNextClick}
          sx={custombuttonRight}
        >
          
          <ChevronRightIcon />
        </Box>
      </Box>
    </Box>
  );
}

export default CareerSlider;