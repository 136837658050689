import React from 'react';
import './Bubble.css';

const Bubble = () => {
  return (
    <div className="bubble-body">
      <div className='container'>
        <div className="bubbles">
          <span style={{ '--n': 11 }}></span>
          <span style={{ '--n': 22 }}></span>
          <span style={{ '--n': 13 }}></span>
          <span style={{ '--n': 26 }}></span>
          <span style={{ '--n': 28 }}></span>
          <span style={{ '--n': 15 }}></span>
          <span style={{ '--n': 12 }}></span>
          <span style={{ '--n': 30 }}></span>
          <span style={{ '--n': 14 }}></span>
          <span style={{ '--n': 20 }}></span>
          <span style={{ '--n': 32 }}></span>
          <span style={{ '--n': 19 }}></span>
          <span style={{ '--n': 34 }}></span>
          <span style={{ '--n': 11 }}></span>
          <span style={{ '--n': 52 }}></span>
          <span style={{ '--n': 3 }}></span>
          <span style={{ '--n': 6 }}></span>
          <span style={{ '--n': 8 }}></span>
          <span style={{ '--n': 5 }}></span>
          <span style={{ '--n': 2 }}></span>
          <span style={{ '--n': 90 }}></span>
          <span style={{ '--n': 84 }}></span>
          <span style={{ '--n': 97 }}></span>
          <span style={{ '--n': 84 }}></span>
          <span style={{ '--n': 98 }}></span>
          <span style={{ '--n': 80 }}></span>
          <span style={{ '--n': 92 }}></span>
          <span style={{ '--n': 89 }}></span>
          <span style={{ '--n': 74 }}></span>
          <span style={{ '--n': 61 }}></span>
          <span style={{ '--n': 72 }}></span>
          <span style={{ '--n': 63 }}></span>
          <span style={{ '--n': 76 }}></span>
          <span style={{ '--n': 68 }}></span>
          <span style={{ '--n': 75 }}></span>
          <span style={{ '--n': 62 }}></span>
          <span style={{ '--n': 40 }}></span>
          <span style={{ '--n': 54 }}></span>
          <span style={{ '--n': 47 }}></span>
          <span style={{ '--n': 54 }}></span>
          <span style={{ '--n': 48 }}></span>
          <span style={{ '--n': 50 }}></span>
          <span style={{ '--n': 42 }}></span>
          <span style={{ '--n': 59 }}></span>
          <span style={{ '--n': 44 }}></span>
        </div>
      </div>
    </div>
  );
}

export default Bubble;
