import React from 'react'
import { Team, TeamBox, TeamCardBox, TeamDescription, TeamHead } from '../../teamgroupStyle'
import TeamCard from '../../teamCards'
import { testing } from '../../TeamInfo'

function Testing() {
  return (
    <>
    <Team>
        <TeamBox>
            <TeamHead>
            Bug Busters
            </TeamHead>
            <TeamDescription>
            Meet our Bug Busters, the vigilant QA testers dedicated to squashing every bug. Ensuring flawless performance and seamless user experiences. Excellence guaranteed. 
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {testing.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
    </>
  )
}

export default Testing
