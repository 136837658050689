import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Dystopian, Lufga,Arial, sans-serif", 

    h1: {
      fontFamily: "Dystopian,Lufga, Arial, sans-serif",
      fontSize: '2.5rem',
      fontWeight: 900, // Dystopian_Black
    },
    h2: {
      fontFamily: "Dystopian,Lufga, Arial, sans-serif",
      fontWeight: 700, // Dystopian_Bold
    },
    body1: {
      fontFamily: "Dystopian,Lufga, Arial, sans-serif",
      // fontWeight: 400, // Dystopian_regular
    },
    body2: {
      fontFamily: "Dystopian,Lufga, Arial, sans-serif",
      fontWeight: 300, // Dystopian_light
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Scrollbar styles
        '::-webkit-scrollbar': {
          width: '5px',
          height: 'px',
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent', 
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'gray', 
          borderRadius: '8px',
        },
        // '::-webkit-scrollbar-thumb:hover': {
        //   backgroundColor: 'orange', 
        // },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "rgba(15, 1, 33, 1)",
      light: "#FFFFFF",
      lightOrange: "#eab857",
      secondaryColor: "#EBB94E",
    },
    lightWhiteColor:'rgba(255, 255, 255, 1)',
    yellowColor:'rgba(254, 198, 29, 1)',
    lightBlack:'rgba(3, 2, 17, 1)',
    lightGray:'#9B96B0',
    lightBlue:'rgba(63, 23, 182, 1)'
  },
});