import React from 'react'
import { TeamCardStyle, TeamImage, TeamName, TeamRole } from '../teamgroupStyle'
import { Typography } from '@mui/material'

function TeamCard({data}) {
  return (
    <>
    <TeamCardStyle>
      <TeamImage>
        <img src={data.image} style={{width:'100%'}}/>
      </TeamImage>
      <TeamName>
        {data.name}
      </TeamName>
      <TeamRole>
        {data.role}
      </TeamRole>
      
    </TeamCardStyle>

    </>
  )
}

export default TeamCard
