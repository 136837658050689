


import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay} from 'swiper/modules';
import { Box, Button } from '@mui/material';
import { StyledBox } from '../teamgroupStyle'; // Import your StyledBox

// Sample image import
import imgTest from '../../../../asset/images/chennaiteam/How group image.png';
import { btnContainer, buttonLeft, buttonRight } from '../teamGroup_style';
import Leader from './leaders';
import Analist from './analist';
import Developer from './codingCrew';
import Design from './designteam';
import Content from './contentWriter';
import Testing from './testing';
import Sales from './sales';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ChennaiBanner } from '../TeamInfo';


function ChennaiTeam() {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  const handleClick = (index) => {
    // swiperRef.current.swiper.slideTo(index);
    // setActiveIndex(index);
    swiperRef.current.swiper.slideToLoop(index);
    setActiveIndex(index);
  };

  const handleSlideChange = (swiper) => {
     // setActiveIndex(swiper.activeIndex);
    const index = swiper.realIndex; 
    setActiveIndex(index);
  };

  return (
    <>
    <Box sx={{ position: 'relative',maxWidth:'1244px',margin:'0px auto',marginBottom:'50px'}}>
      <Swiper
         ref={swiperRef}
         slidesPerView={1}
         spaceBetween={30}
         loop={true}
         pagination={false} 
         navigation={false} 
         modules={[Pagination, Navigation,Autoplay]}
         onSlideChange={handleSlideChange}
        //  className="mySwiper"
        //  autoplay={{
        //   delay: 3000, // Delay between slides in milliseconds (3 seconds in this case)
        //   disableOnInteraction: false, // Continue autoplay after user interactions
        // }}

        
      >
        {ChennaiBanner.map((data, index) => (
          <SwiperSlide key={index}>
            <StyledBox>
              <img src={data.images} style={{ maxWidth: '100%', height: 'auto' }} alt={`Slide ${index + 1}`} />
            </StyledBox>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <Box
        sx={btnContainer}
      >
        <Box
          onClick={handlePrevClick}
          sx={buttonLeft}
        >
          <ChevronLeftIcon />
        </Box>
        <Box
          onClick={handleNextClick}
          sx={buttonRight}
        >
          
          <ChevronRightIcon />
        </Box>
      </Box>

      {/* Custom Pagination */}
      <Box
        sx={{
          top:'100%',
          position: 'absolute',
          bottom: 20, // Increased space from bottom
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 1,
          zIndex: 10, // Ensure pagination dots are above other content
          padding: 1, // Padding to make background more visible
          borderRadius: 1, 
          
        }}
      >
        {ChennaiBanner.map((data, index) => (
          <Button
            key={index}
            onClick={() => handleClick(index)}
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              border:'1px solid #fff',
              minWidth: 0,
              padding: 0,
              backgroundColor: activeIndex === index ? '#ccc' : 'transparent',
              '&:hover': {
                backgroundColor: activeIndex === index ? '#ccc' : '#ccc',
              },
            }}
          />
        ))}
      </Box>
      
    </Box>
      <Leader/>
      <Analist/>
      <Developer/>
      <Design/>
      <Content/>
      <Testing/>
      <Sales/>
    </>
  );
}

export default ChennaiTeam;
