// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: 'Lufga, Arial, sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f3f5f8;
        padding: 0;
        box-sizing: border-box;
    padding-right: 0!important;

    /* overflow: unset!important; */
}

code {
    font-family: 'Dystopian,Lufga,Syne Arial, sans-serif';
    color: rgba(255, 255, 255, 1);
}

#root {
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,SAAS;IACT,uCAAuC;IACvC,mCAAmC;IACnC,kCAAkC;IAClC,mBAAmB;QACf,UAAU;QACV,sBAAsB;IAC1B,0BAA0B;;IAE1B,+BAA+B;AACnC;;AAEA;IACI,qDAAqD;IACrD,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["html{\n    scroll-behavior: smooth;\n}\nbody {\n    margin: 0;\n    font-family: 'Lufga, Arial, sans-serif';\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background: #f3f5f8;\n        padding: 0;\n        box-sizing: border-box;\n    padding-right: 0!important;\n\n    /* overflow: unset!important; */\n}\n\ncode {\n    font-family: 'Dystopian,Lufga,Syne Arial, sans-serif';\n    color: rgba(255, 255, 255, 1);\n}\n\n#root {\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
