import { Container } from '@mui/material'
import React from 'react'
import AboutusBanner from './about-banner'
import AboutusOurStory from './our-story'

import Director from './director'

import Dna from './DNA'


export default function Aboutus() {
    return (
        <Container disableGutters maxWidth={false} style={{ padding: 0 }}>
            <AboutusBanner />
            <Director/>
            <AboutusOurStory />
            <Dna />

        </Container>
    )
}
