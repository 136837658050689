import styled from '@emotion/styled';

export const LocationDiv = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginTop: '64px',
    marginTop: 'auto',
    // [theme.breakpoints.down('sm')]: {
    //     marginTop: '35px',
        
    // },
    
}))

export const LocationImg = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  
}))

export const ArrowImg = styled("a")(({ theme }) => ({
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
  
}))
export const CurentOpeningBox = styled('div')(({ theme }) => ({
    // flexBasis: '26%',    
    marginLeft: '0 !important',
    border: '1px solid #fff',
    borderRadius: '4px',
    padding: '32px',
    color: '#fff',
    transition: 'all 0.9s ease',
    position: 'relative',
    display: 'inline-block',
    overflow: 'hidden',
    height: '300px',
    display: 'flex',
    transition: 'all 0.8s cubic-bezier(0.23, 1, 0.320, 1)',
    '.text-content': {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    '&:active': {
        scale: 0.95,
    },
    '.box-inner': {       
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20px',
        height: '20px',
        backgroundColor: '#FEC61D',        
        opacity: 0,
        transition: 'all 1.5s cubic-bezier(0.23, 1, 0.320, 1)',
    },
    '&:hover': {
                
                color: "#0B1320",
                border: "1px solid transparent",
                
                
                '.box-inner': {
                    width: '100%',
                    height: '100%',
                    opacity: 1,
                    
                },
                a: {
                    opacity: "1",
                    visibility: "visible"
                },
                '.white-location': {
                    display: 'none',

                },
                '.blck-location': {
                    display: 'block',
                    width: "15px",

                }



            },
            '.white-location': {
                display: 'block',
                width: "15px",

            },
            '.blck-location': {
                display: 'none'

        },
    '@media (min-width: 992px) and (max-width: 1192px)': {       
       padding: '20px',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {        
        padding: '20px',
    },
    '@media (min-width: 600px) and (max-width: 767px)': {        
        padding: '20px',
    },
    '@media (min-width: 320px) and (max-width: 599px)': {       
        padding: '15px',
    },
  }));

  export const GridWrapper = styled("div")(({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '24px',
    '@media (min-width: 992px) and (max-width: 1192px)': {
        gridTemplateColumns: '1fr 1fr 1fr'
        
     },
     '@media (min-width: 768px) and (max-width: 991px)': {
        gridTemplateColumns: '1fr 1fr'
         
     },
     '@media (min-width: 600px) and (max-width: 767px)': {
        gridTemplateColumns: '1fr'
        
     },
     '@media (min-width: 320px) and (max-width: 599px)': {
        gridTemplateColumns: '1fr'
        
     },
  }))

export const Form = styled("form")(({ theme }) => ({
   display: 'flex',
    alignItems: 'center',
    gap: '52px',
    padding: '0 10px',
    marginTop: '50px',
    marginBottom: '80px',
    [theme.breakpoints.down('xl')]: {
        marginTop: '50px',
        marginBottom: '80px',
        flexWrap: 'nowrap'
    },
    [theme.breakpoints.down('lg')]: {
        marginTop: '40px',
        marginBottom: '60px',
        flexWrap: 'nowrap',
        padding: '0',
        gap: '35px'
    },
    [theme.breakpoints.down('md')]: {
        marginTop: '40px',
        marginBottom: '60px',
        flexWrap: 'nowrap'
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '30px',
        marginBottom: '40px',
        flexWrap: 'nowrap',
        flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
        marginTop: '20px',
        marginBottom: '40px',
        flexWrap: 'wrap'
    },
    // '@media (min-width: 992px) and (max-width: 1192px)': {
    //     marginTop: '50px',
    //     marginBottom: '80px',
    //     flexWrap: 'nowrap'
    // },
    // '@media (min-width: 768px) and (max-width: 991px)': {
    //     marginTop: '40px',
    //     marginBottom: '60px',
    //     flexWrap: 'nowrap'
    // },
    // '@media (min-width: 600px) and (max-width: 767px)': {
    //     marginTop: '30px',
    //     marginBottom: '40px',        
    //     flexWrap: 'wrap'
    // },
    // '@media (min-width: 320px) and (max-width: 599px)': {
    //     marginTop: '20px',
    //     marginBottom: '40px',
    //     flexWrap: 'wrap'
    // },
  
}))

// export const FormItem = styled("div")(({ theme }) => ({
//     '@media (min-width: 992px) and (max-width: 1192px)': {
//        width: '50%'
//     },
//     '@media (min-width: 768px) and (max-width: 991px)': {
//         width: '50%'
//     },
//     '@media (min-width: 600px) and (max-width: 767px)': {
//         width: '100%'
//     },
//     '@media (min-width: 320px) and (max-width: 599px)': {
//        width: '100%'
//     },
// }))

