import React, { useEffect, useRef } from 'react';
import { Box, Grid, useMediaQuery, useTheme, Typography } from '@mui/material';
import anime from 'animejs/lib/anime.es.js';
import '../landing-clients/Slider.css'; // Ensure this file includes the required styles
import { sideBars } from '../../../constants';

export default function BarLine() {
    const textRefs = useRef([]);
    const theme = useTheme();
    const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        // Wrap each letter in a span with the class 'letter'
        textRefs.current.forEach((textRef) => {
            if (textRef) {
                textRef.innerHTML = textRef.textContent.replace(
                    /\S/g,
                    "<span class='letter'>$&</span>"
                );
            }
        });

        // Create an anime.js timeline for each text element individually
        sideBars.forEach((_, index) => {
            anime.timeline({
                loop: true,
                delay: 3000 * index, // Delay before the animation starts
            })
            .add({
                targets: textRefs.current[index].querySelectorAll('.letter'),
                translateY: ['1.1em', 0],
                opacity: [0, 1],
                duration: 750,
                easing: 'easeOutExpo',
                delay: (el, i) => 50 * i,
            })
            .add({
                targets: textRefs.current[index],
                opacity: [1, 0],
                duration: 1000,
                easing: 'easeOutExpo',
                delay: 1000, // Delay before the fade-out starts
            });
        });
    }, []);

    const animatedContent = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 'fit-content',
            }}
        >
            {sideBars.map((content, index) => (
                <Typography
                    className="ml6"
                    sx={{
                        fontSize: { xs: '20px', sm: '26px', md: '30px', lg: '30px' },
                        fontWeight: 700,
                        textAlign: 'center',
                        margin: '0 2rem',
                        whiteSpace: 'nowrap',
                    }}
                    ref={(el) => (textRefs.current[index] = el)}
                    key={index}
                >
                    {content}
                </Typography>
            ))}
        </Box>
    );

    return (
        <Box
            sx={{
                background: 'rgba(254, 198, 29, 1)',
                padding: { xs: '2rem', sm: '2rem', md: '2.2rem' },
                width: '100%', // Make the container full width
                position: 'relative',
                left: {
                    md: '-30px',
                    xs: '-15px'
                },
                top: {
                    md: '0',
                    sm: '40px',
                    xs: '40px',
                },
                transform: 'rotate(-4.02deg)',
                marginTop: '-70px',
                marginBottom: '-85px',
                overflow: 'hidden', // Prevent horizontal scrollbar
            }}
        >
            <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    margin: 0,
                }}
            >
                {isMediumOrSmaller ? (
                    <marquee scrollamount="15" direction="left" behavior="scroll" style={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            {sideBars.map((content, index) => (
                                <Box key={index} sx={{ display: 'inline-block', width: '100%', padding: '0 3rem' }}>
                                    <Typography
                                        className="ml6"
                                        sx={{
                                            fontSize: { xs: '20px', sm: '26px', md: '30px' }, // Responsive font size
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                        }}
                                        ref={(el) => (textRefs.current[index] = el)}
                                    >
                                        {content}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </marquee>
                ) : (
                    animatedContent
                )}
            </Grid>
        </Box>
    );
}
