export const apiRouters={

    //contact us

    contactPost:'api/contactUsForm/submit',
    contactSteps:'api/nextsteps/getall',
    contactHeadInfo:'api/teamheads/getall',
    // contactLogo:'api/logos/getall',


    //teams api

    teamBanner:'api/banner',
    
    

    //home page 

    getAllNotified :'/api/notifications/getall',
}