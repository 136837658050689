import { Images } from '../asset/images'

export const Projects = [
    {
        image: Images.LandingProject1,
        title: 'New concept properties',
        description: 'Property Portal',
    },
    {
        image: Images.LandingProject2,
        title: 'Neuranest',
        description: 'CMS',
    },
    {
        image: Images.LandingProject3,
        title: "Ck's Bakery",
        description: 'Web Design',
    },
    {
        image: Images.LandingProject4,
        title: 'kailasa kaladhara',
        description: 'CMS',
    },
    {
        image: Images.LandingProject5,
        title: 'Mycelium',
        description: 'Branding',
    },
]

export const TestimonialSlides = [
    {
        title: 'New concept properties',
        description:
            'House of Websites transformed our brand identity with a fresh, innovative approach that truly captures our essence. Their creative team not only understood our vision but elevated it to new heights. Our brand stands out in the crowded market, thanks to their expertise.',
        image: Images?.MaskGroup,
        projectName: ' NCP Project',
    },
    {
        title: 'House Of Websites',

        description:
            'Working with House of Websites was a game-changer for us. Their design and development team crafted a website that is both visually stunning and highly functional. They turned our complex ideas into an intuitive, user-friendly experience. Highly recommended!',
        image: Images?.MaskGroup,
        projectName: 'Neuranest',
    },
    {
        title: 'House Of Websites',

        description:
            'House of Websites significantly improved our search engine rankings and online visibility. Their SEO strategies are top-notch and data-driven, leading to a noticeable increase in traffic and conversions. Their attention to detail and dedication are unmatched.',
        image: Images?.MaskGroup,
        projectName: "Ck's Bakery",
    },
    {
        title: 'House Of Websites',

        description:
            'Our e-commerce site has never looked better or performed so efficiently, all thanks to House of Websites. They provided us with a sleek, easy-to-navigate platform that has greatly enhanced our sales and customer satisfaction. Their team is responsive and professional.',
        image: Images?.MaskGroup,
        projectName: 'kailasa kaladhara',
    },
    {
        title: 'House Of Websites',

        description:
            "House of Websites exceeded our expectations across the board. From branding to development, SEO, and e-commerce, their team delivered exceptional results with creativity and professionalism. They've become our go-to partner for all our digital needs.",
        image: Images?.MaskGroup,
        projectName: 'Mycelium',
    },
]

export const accordionData = [
    {
        panel: 'panel1',
        title: 'Branding',
        content:
            'Crafting unique identities, we transform ideas into memorable brands, ensuring your business stands out with a compelling visual presence.',
        image: Images.Branding,

        progressValue: 35,
        height: '130px',
        topImage: '-90px',
        topPara: '-75px',
    },
    {
        panel: 'panel2',
        title: 'Design & Development',
        content:
            'Creating visually stunning, user-centric websites that balance aesthetics with functionality, driving engagement and delivering seamless digital experiences.',
        image: Images.DesignAndDevelopment,
        progressValue: 35,
        height: '130px',
        topImage: '-90px',
        topPara: '-75px',
    },
    {
        panel: 'panel3',
        title: 'SEO',
        content:
            'Optimizing your online presence with strategic SEO techniques, ensuring your website ranks high and attracts the right audience organically.',
        image: Images.SEO,
        progressValue: 35,
        height: '130px',
        topImage: '-90px',
        topPara: '-75px',
    },
    {
        panel: 'panel4',
        title: 'Ecommerce',
        content:
            'Building robust eCommerce platforms tailored to your business, empowering you to sell efficiently and grow in the digital marketplace.',
        image: Images.Ecommerce,
        progressValue: 35,
        height: '135px',
        topImage: '-95px',
        topPara: '-90px',
    },
]

export const sideBars = [
    ' Creative Agency',
    'Design Magic',
    'Boldly Crafted',
    'Elevate Ideas',
    'Unleash Awesome',
]

export const UserData = [
    {
        img: Images.Eugene1,
        in: Images.Linkedin,
        name: 'Manekshaw',
        roll: 'Head of Growth',
    },
    {
        img: Images.Eugene1,
        in: Images.Linkedin,
        name: 'Rebecca',
        roll: 'Sales Team',
    },
    {
        img: Images.Eugene1,
        in: Images.Linkedin,
        name: 'Kesavan',
        roll: 'Sales Team',
    },
]
export const multi_Diversity = [
    {
        div_img: Images.Innovation,
        div_headline: 'Innovation',
        div_para:
            'We value fresh ideas and creative thinking, constantly seeking ways to improve, adapt, and make meaningful changes in our work.',
        // fade_time: '1000',
    },
    {
        div_img: Images.Colabrate,
        div_headline: 'Collaboration',
        div_para:
            'We believe in strong teamwork, working together closely, sharing ideas, and supporting each other to achieve the best possible outcomes.',
        // fade_time: '1200',
    },
    {
        div_img: Images.Empathy,
        div_headline: 'Empathy',
        div_para:
            "We care deeply about understanding others' feelings and needs, ensuring our work reflects kindness, compassion, and genuine human connection.",
        // fade_time: '1400',
    },
    {
        div_img: Images.Excelence,
        div_headline: 'Excellence',
        div_para:
            'We strive to do our absolute best in every task, delivering high-quality work that meets high standards and expectations.',
        // fade_time: '1600',
    },
    {
        div_img: Images.Adoptablity,
        div_headline: 'Adaptability',
        div_para:
            'We embrace change with open minds, quickly adjusting to new situations and challenges, ensuring we stay effective and relevant.',
        // fade_time: '1800',
    },
    {
        div_img: Images.Acountablity,
        div_headline: 'Accountability',
        div_para:
            'We take full responsibility for our actions, always following through on commitments and maintaining integrity in everything we do.',
        // fade_time: '2000',
    },
]
export const Current_opening = [
    {
        current_p: 'Tester',
        current_p_cont:
            'Ensure product quality through rigorous testing. Identify and resolve issues, ensuring seamless performance and a flawless user experience across all platforms.',
        current_loc_area: 'Chennai, India',
        fade_time: '1000',

        postedDate: '2024-08-20',
        experience: '2 - 5 years',
        salary: '10 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Front End Developer',
        current_p_cont:
            'Be part of a dynamic team crafting intuitive, responsive user interfaces that enhance digital interactions and elevate user experiences.',
        current_loc_area: 'Chennai, India',
        fade_time: '1300',

        postedDate: '2022-08-20',
        experience: '0 - 5 years',
        salary: '5 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Back End Developer',
        current_p_cont:
            'Develop cutting-edge solutions, ensuring seamless functionality and robust performance across platforms, driving the technical backbone of our projects.',
        current_loc_area: 'Chennai, India',
        fade_time: '1600',

        postedDate: '2022-08-20',
        experience: '0 years',
        salary: '1 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Content Writer',
        current_p_cont:
            'Create compelling content that engages audiences and drives results. Transform ideas into clear, impactful messaging that resonates with target readers.',
        current_loc_area: 'Chennai, India',
        fade_time: '1900',

        postedDate: '2024-08-20',
        experience: '2 years',
        salary: '3 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Product Designer',
        current_p_cont:
            'Design innovative, user-centered products that blend aesthetics with functionality. Drive the creation of intuitive, engaging solutions that meet user needs.',
        current_loc_area: 'Chennai, India',
        fade_time: '1200',

        postedDate: '2024-07-20',
        experience: '5 years',
        salary: '10 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Data Analyst',
        current_p_cont:
            'Transform complex data into actionable insights. Analyze trends, patterns, and metrics to guide strategic decisions and optimize performance across projects.',
        current_loc_area: 'Chennai, India',
        fade_time: '1400',

        postedDate: '2024-01-20',
        experience: '7 years',
        salary: '20 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
]
export const Careerslider = [
    {
        slideimg: Images.SlideImg1,
    },
    {
        slideimg: Images.SlideImg2,
    },
    {
        slideimg: Images.SlideImg3,
    },
    {
        slideimg: Images.SlideImg4,
    },
    {
        slideimg: Images.SlideImg5,
    },
]

export const Glassdoordata = [
    {
        slideimg: Images.EmpProfile1,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Mubal Raj',
        emprole: 'UI/UX Designer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile2,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Esther',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile3,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Praveenkumar',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile4,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Hemalatha',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile5,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Madhumitha',
        emprole: 'Senior Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile6,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Vishal Anand',
        emprole: 'Business Analyst, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile7,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Sureshkumar',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
]

export const contactTeamInfo = [
    ,
    //     {
    //     image:Images.ContactImg,
    //     name:'Manekshaw',
    //     role:'Head of Growth',
    // }
    {
        image: Images.ContactVishal,
        name: 'Vishal',
        role: 'Buisness Consultant',
        link: 'https://www.linkedin.com/in/vishal-anand-9666a478/',
    },
    {
        image: Images.ContactSridhar,
        name: 'Sridhar V',
        role: 'Buisness Consultant',
        link: 'https://www.linkedin.com/in/sridhar-v-6a933a22b/',
    },
]

// export  const contactStatus = [
//     {
//         image: Images.ContactImg,
//         name: 'Manekshaw',
//         role: 'Head of Growth',
//     },
//     {
//         image: Images.ContactImg,
//         name: 'Rebecca',
//         role: 'Sales Team',
//     },
//     {
//         image: Images.ContactImg,
//         name: 'Kesavan',
//         role: 'Sales Team',
//     },
// ]

export const contactStatus = [
    {
        detail: 'Guaranteed response within 24 hours',
    },
    {
        detail: 'Confidentiality assured: NDA available upon request',
    },
    {
        detail: 'Direct access to our expert web development specialists',
    },
]
export const countData = [
    {
        start: 0,
        end: 4,
        projectName: 'Years of Services',
        class: 'item1',
        number: '80',
        fade_time: '1000',
        add: '+',
    },
    {
        start: 0,
        end: 80,
        projectName: 'Team Members',
        class: 'item2',
        number: '80',
        fade_time: '1200',
        add: '+',
    },
    {
        start: 0,
        end: 40,
        projectName: 'Projects',
        class: 'item3',
        number: '80',
        fade_time: '1400',
        add: '+',
    },
    {
        start: 0,
        end: 70,
        projectName: 'Happy Clients',
        class: 'item4',
        number: '80',
        fade_time: '1600',
        add: '+',
    },
    {
        start: 0,
        end: 2,
        projectName: 'Location',
        class: 'item5',
        number: '80',
        fade_time: '1800',
    },
]

export const contactSteps = [
    {
        step: 'STEP 1',
        image: Images.Video,
        des: 'Our team reaches out to you within 24 hours.',
    },
    {
        step: 'STEP 2',
        image: Images.AvatarUser,
        des: 'We discuss your project details and gather all necessary requirements.',
    },
    {
        step: 'STEP 3',
        image: Images.SearchWhat,
        des: 'We analyze, review, and finalize the requirements to create the brief.',
    },
    {
        step: 'STEP 4',
        image: Images.StepMail,
        des: 'We prepare the estimates and present you with the proposal.',
    },
]
