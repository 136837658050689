import React from 'react'

import { CubeBlur, PurpleBlur, StarBlur, Team, TeamBox, TeamCardBox, TeamDescription, TeamHead, TeamOut,Star } from '../../teamgroupStyle'
import { backend, cuddaloreCoders, cuddaloreCodingCrew, developerObject } from '../../TeamInfo'
import TeamCard from '../../teamCards'
import blur from '../../../../../asset/images/cubeblur.png'
import star from '../../../../../asset/images/Star 17.png'
import purp from '../../../../../asset/images/purpleblur.png'
import { Images } from '../../../../../asset/images'

function CudDeveloper() {
  return (
    <>
    
  <TeamOut>
  <CubeBlur>
          <img src={Images.Cubeblur} style={{width:'100%'}}/>
        </CubeBlur>
        <PurpleBlur>
          <img src={Images.Purpleblur} style={{width:'100%'}}/>
        </PurpleBlur>

  <Team>
        <TeamBox>
            <TeamHead>
            Coding Crew
            </TeamHead>
            <TeamDescription>
            Our coding experts bring digital dreams to life, delivering flawless, high-performance websites with precision and innovation. 
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {cuddaloreCoders.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>

        
       </Team>
  </TeamOut>




   <TeamOut>

   <StarBlur>
          <img src={Images.Purpleblur} style={{width:'100%'}}/>
        </StarBlur>
        {/* <Star>
          <img src={star} style={{width:'100%'}}/>
        </Star> */}
   <Team>
       <TeamCardBox >  
            {cuddaloreCodingCrew.map((data)=><TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
   </TeamOut>
    </>
  )
}

export default CudDeveloper
