import React, { useState } from 'react'
import { Images } from '../../../asset/images'
import {
    Box,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    FormControl,
} from '@mui/material'
import {
    ArrowImg,
    LocationDiv,
    LocationImg,
    Form,
    GridWrapper,
} from './openings.style'
import { AppContainer } from '../career.style'
import { Current_opening } from '../../../constants'
import CurentopeningsList from './CurrentOpeningsList'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'

function Curentopenings() {
    const [age, setAge] = useState('')

    const handleChange = (event) => {
        setAge(event.target.value)
    }
    return (
        <Box id="career-navigate"
            sx={{
                backgroundColor: '#070707',
                paddingTop: {
                    xl: '100px',
                    lg: '100px',
                    md: '80px',
                    sm: '50px',
                    xs: '40px',
                },
                paddingBottom: '80px',
            }}
        >
            <AppContainer>
                <Box sx={{ backgroundColor: '#070707', color: '#fff' }}>
                    <Box>
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: {
                                    xl: '64px',
                                    lg: '64px',
                                    md: '52px',
                                    sm: '48px',
                                    xs: '28px',
                                },
                                fontFamily: 'Dystopian',
                                fontWeight: '700',
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            Current Openings
                        </Typography>
                        <Form action="">
                            <Box
                                sx={{
                                    width: {
                                        xl: '30%',
                                        lg: '30%',
                                        md: '30%',
                                        sm: '50%',
                                        xs: '100%',
                                    },
                                }}
                            >
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': {
                                            width: '100%',
                                            marginBottom: '-16px',
                                        },
                                        color: '#fff',
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        id="standard-basic"
                                        placeholder="Enter the keyword to search"
                                        variant="standard"
                                        InputProps={{
                                            startAdornment: (
                                                <img src={Images.Search} />
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                            },
                                            
                                            '& .MuiInputBase-input::placeholder':
                                                {
                                                    fontSize: '16px',
                                                    color: 'white',
                                                    // background:'red',
                                                    fontFamily: 'Lufga',
                                                    fontWeight: 300,
                                                },

                                            '& .MuiInput-root': {
                                                color: '#fff ',

                                                gap: '10px',
                                                // Bottom border
                                                '&:before': {
                                                    borderColor:
                                                        '#7c7c7c !important',
                                                    borderWidth: '1px',
                                                },
                                                '&:after': {
                                                    borderColor: '#fff',
                                                    color: 'rgba(124, 124, 124, 1)',
                                                },
                                            },

                                            '& .MuiInputLabel-standard': {
                                                color: 'rgba(124, 124, 124, 1)',
                                                fontSize: '16px',
                                                fontFamily: 'Lufga',
                                                fontWeight: '400',
                                                '&.Mui-focused': {
                                                    color: '#fff',
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: {
                                        xl: '30%',
                                        lg: '30%',
                                        md: '30%',
                                        sm: '50%',
                                        xs: '100%',
                                    },
                                }}
                            >
                                <FormControl
                                    variant="standard"
                                    sx={{
                                        width: '100%',
                                        '& .MuiInput-root': {
                                            color: '#7c7c7c',
                                            fontSize: '16px',
                                            fontFamily: 'Lufga',
                                            fontWeight: '300',
                                            // Bottom border
                                            '&:before': {
                                                borderColor:
                                                    '#7c7c7c !important',
                                                borderWidth: '1px',
                                            },
                                            '&:after': {
                                                borderColor: '#fff',
                                                color: '#7c7c7c',
                                            },
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: '#fff',
                                        },
                                        // Label
                                        '& .MuiInputLabel-standard': {
                                            color: '#7c7c7c',
                                            fontSize: '16px',
                                            fontFamily: 'Lufga',
                                            fontWeight: '300',
                                            '&.Mui-focused': {
                                                color: '#7c7c7c',
                                                fontSize: '16px',
                                                fontFamily: 'Lufga',
                                                fontWeight: '300',
                                            },
                                        },
                                    }}
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        sx={{
                                            color: '#7c7c7c',
                                            fontSize: '16px',
                                            fontFamily: 'Lufga',
                                            fontWeight: '400',
                                        }}
                                    >
                                        Select Location
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={age}
                                        onChange={handleChange}
                                        label="Select Location"
                                        width="100%"
                                        // placeholder='Select Location'
                                        border="1px solid #fff"
                                        IconComponent={
                                            KeyboardArrowDownSharpIcon
                                        }
                                    >
                                        <MenuItem value=""  sx={{
                                                fontFamily: 'Lufga',
                                            }}>
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem
                                            value={10}
                                            sx={{
                                                fontFamily: 'Lufga',
                                            }}
                                        >
                                            Chennai
                                        </MenuItem>
                                        <MenuItem
                                            value={20}
                                            sx={{
                                                fontFamily: 'Lufga',
                                            }}
                                        >
                                            Cuddalore
                                        </MenuItem>
                                        <MenuItem
                                            sx={{
                                                fontFamily: 'Lufga',
                                            }}
                                            value={30}
                                        >
                                            Viluppuram
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Form>
                    </Box>
                    {/* <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            flexWrap: 'wrap',
                            gap: { sm: '18px', xs: '16px' },
                            justifyContent: 'space-evenly',
                        }}
                    >
                        {Current_opening.map((current_open, index) => (
                            <CurentopeningsList
                                key={index}
                                current_p={current_open.current_p}
                                current_p_cont={current_open.current_p_cont}
                                current_loc_area={current_open.current_loc_area}
                            />
                        ))}
                    </Stack> */}
                    <GridWrapper>
                        {Current_opening.map((current_open, index) => (
                            <CurentopeningsList
                                key={index}
                                current_p={current_open.current_p}
                                current_p_cont={current_open.current_p_cont}
                                current_loc_area={current_open.current_loc_area}
                            />
                        ))}
                    </GridWrapper>
                </Box>
            </AppContainer>
        </Box>
    )
}

export default Curentopenings
