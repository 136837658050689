import { Box, boxClasses, styled } from "@mui/material";

export const custombuttonLeft ={
      backgroundColor: '#fff',
      color: '#3E3936',
      borderRadius: '50%',
      width: 40,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: 'rgba(254, 198, 29, 1)',
      },
  }
  export const custombuttonRight ={
    backgroundColor: '#fff',
    color: '#3E3936',
      borderRadius: '50%',
      width: 40,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: 'rgba(254, 198, 29, 1)',
      },
  }
  
  export const btnContainer = {
    position: 'absolute',
    top: '62%',
    right: '43%',
    gap: '20px',
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    transform: 'translateY(-50%)',
    // px: 2,
    zIndex: 10,
    cursor: 'pointer',
    display: 'none',
    '@media (min-width:992px) and (max-width: 1192px)':{
       top: '72%',
       right: '41%'
    },
    '@media (min-width:768px) and (max-width: 991px)':{
       top: '68%',
       right: '36%'
    },
    '@media (min-width:600px) and (max-width: 767px)':{
      top: 'unset',
      bottom: '3%',
      right: '8%'
    },
    '@media (min-width:320px) and (max-width: 599px)':{
       top: 'unset',
       bottom: '3%',
       right: '8%'
    }
  }
  
  export const tabStyle = {
    '@media (max-width:500px)':{
       padding:'10px 5px',
    }
  }
  export const GridContainer = styled("div")(({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    '@media (min-width:992px) and (max-width: 1192px)':{
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (min-width:768px) and (max-width: 991px)':{
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (min-width:600px) and (max-width: 767px)':{
      gridTemplateColumns: '1fr'
    },
    '@media (min-width:320px) and (max-width: 599px)':{
       gridTemplateColumns: '1fr'
    }
  }))
  export const StyledBox = styled(Box)(({ theme }) => ({
    // boxShadow: theme.shadows[3],
    maxWidth: '100%',
    margin: '0px auto',
//     '& img:hover': {
//     [`& + .btn-container`]: {
//       display: 'flex', // Show the button container when the image is hovered
//     },
//   },

    
  }));

  export const GlassdoorImg = styled("div")(({theme}) => ({
    width: '60%',
    margin: '0 auto',
    
    // Media queries
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  
    // Apply styles when a child with class "img" is present
    '& img': {
      width: '100%',
    },
  }))
