import React from 'react'
import TeamBanner from './teamBanner'
import GroupSlider from './teamGroup'
import { Box } from '@mui/material'

export default function Ourteam() {
  return (
    <Box sx={{
      backgroundColor:'#0F0121',
     fontFamily: 'Lufga',
    }}>
      <TeamBanner/>
      <GroupSlider/>
    </Box>
  )
}
