import React, { useState } from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    IconButton,
    InputLabel,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import {
    ContactInformation,
    DialogContentSubContainer,
    DialogMainContainer,
    DialogTitleStyle,
    InputLabelFields,
    PleaseContactInformation,
    ResumeSubContainer,
    ResumeUploadButton,
    SocialMediaSubContainer,
    SubmitButton,
    TextFieldStyle,
} from '../careers-details-style'

const CarrersProposal = ({ open, onClose }) => {
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return
        }
        onClose()
    }

    const [fileName, setFileName] = useState('No file chosen')

    const handleFileChange = (event) => {
        setFormData({
            ...formData,
            resume: event.target.files[0],
        })
    }

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        resume: null,
    })

    const [errors, setErrors] = useState({})

    const validate = () => {
        const errors = {}

        // Full Name validation
        if (!formData.fullName.trim()) {
            errors.fullName = 'Full Name is required'
        }

        // Email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!formData.email.trim()) {
            errors.email = 'Email is required'
        } else if (!emailPattern.test(formData.email)) {
            errors.email = 'Invalid email format'
        }

        // Phone Number validation
        const phonePattern = /^[0-9]{10}$/
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone Number is required'
        } else if (!phonePattern.test(formData.phoneNumber)) {
            errors.phoneNumber = 'Phone number must be exactly 10 digits'
        }

        // Resume validation
        if (!formData.resume) {
            errors.resume = 'Resume is required'
        }

        return errors
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const validationErrors = validate()
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length === 0) {
            // Proceed with form submission
            console.log('Form submitted successfully', formData)
        }
    }

    const [links, setLinks] = useState(['']) 

    const handleAddLink = () => {
        setLinks([...links, ''])
    }

    const handleLinkChange = (index, event) => {
        const newLinks = [...links]
        newLinks[index] = event.target.value 
        setLinks(newLinks)
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth  >
            <form onSubmit={handleSubmit}>
                <DialogTitleStyle>
                    Job Application
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitleStyle>
                <DialogMainContainer>
                    <ContactInformation variant="subtitle2">
                        CONTACT INFORMATION
                    </ContactInformation>
                    <PleaseContactInformation variant="body2">
                        Please enter your contact information.
                    </PleaseContactInformation>
                    <DialogContent sx={{ padding: 0 }}>
                        <DialogContentSubContainer>
                            <div>
                                <InputLabelFields>
                                    Full Name (Name as per Aadhaar card)
                                    <span style={{ color: 'red' }}>* </span>
                                </InputLabelFields>
                                <TextFieldStyle
                                    fullWidth
                                    variant="outlined"
                                    required
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    error={!!errors.fullName}
                                    helperText={errors.fullName}
                                />
                            </div>
                            {/* email */}
                            <div>
                                <InputLabelFields>
                                    Email ID
                                    <span style={{ color: 'red' }}>* </span>
                                </InputLabelFields>
                                <TextFieldStyle
                                    fullWidth
                                    variant="outlined"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </div>

                            {/* phoneNumber */}
                            <div>
                                <InputLabelFields>
                                    Phone Number
                                    <span style={{ color: 'red' }}>* </span>
                                </InputLabelFields>
                                <TextFieldStyle
                                    fullWidth
                                    variant="outlined"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber}
                                />
                            </div>

                            {/* Social media */}
                            <div>
                                <InputLabelFields>
                                    Social Media or Web Links
                                </InputLabelFields>
                                {links.map((link, index) => (
                                    <SocialMediaSubContainer
                                        key={index}
                                        index={index}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            value={link}
                                            onChange={(event) =>
                                                handleLinkChange(index, event)
                                            }
                                            sx={{
                                                margin: '3px 0 0 0',
                                                '& .MuiOutlinedInput-input': {
                                                    padding: '11px 14px',
                                                },
                                            }}
                                        />
                                        {index === links.length - 1 && (
                                            <Button
                                                onClick={handleAddLink}
                                                sx={{
                                                    padding: '9px 14px',
                                                    fontSize: '30px',
                                                    lineHeight: '0.8',
                                                    fontWeight: 300,
                                                    margin: '3px 0 0 0',

                                                    border: '1px solid #D4D4D4',
                                                }}
                                            >
                                                +
                                            </Button>
                                        )}
                                    </SocialMediaSubContainer>
                                ))}
                            </div>

                            {/* Resume  */}
                            <div>
                                <InputLabelFields>
                                    Resume (pdf format only)
                                    <span style={{ color: 'red' }}>* </span>
                                </InputLabelFields>
                                <ResumeSubContainer error={errors.resume}>
                                    <ResumeUploadButton
                                        variant="text"
                                        component="label"
                                    >
                                        Upload Resume
                                        <input
                                            type="file"
                                            accept=".pdf"
                                            hidden
                                            onChange={handleFileChange}
                                        />
                                    </ResumeUploadButton>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Lufga',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            marginLeft:'1rem'
                                        }}
                                    >
                                       {formData.resume ? formData.resume.name : 'No file chosen'}
                                    </Typography>
                                </ResumeSubContainer>
                                {errors.resume && (
                                    <Typography
                                        color="error"
                                        sx={{ fontSize: '12px',fontFamily:'Lufga' }}
                                    >
                                        {errors.resume}
                                    </Typography>
                                )}
                            </div>
                        </DialogContentSubContainer>
                    </DialogContent>

                    <DialogActions sx={{ padding: 0 }}>
                        <SubmitButton
                            variant="contained"
                            fullWidth
                            onClick={handleSubmit}
                        >
                            Submit Application
                        </SubmitButton>
                    </DialogActions>
                </DialogMainContainer>
            </form>
        </Dialog>
    )
}

export default CarrersProposal
