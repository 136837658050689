import React from 'react'
import { Team, TeamBox, TeamCardBox, TeamDescription, TeamHead } from '../../teamgroupStyle'
import {salesTeam} from '../../TeamInfo'
import TeamCard from '../../teamCards'
function Sales() {
  return (
   <>
     <Team>
        <TeamBox>
            <TeamHead>
            Revenue Raiders
            </TeamHead>
            <TeamDescription>
            Our Revenue Raiders are the powerhouse sales team at House of Websites, relentlessly driving growth and surpassing targets with strategic finesse and innovation. 
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {salesTeam.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
   </>
  )
}

export default Sales
