export const directorPara = {
    fontSize:{md:'18px',sm:'16px'},
    fontFamily:'Lufga',
    fontWeight:'500',
}

export const directorRight = {
    display:'flex',
    flexDirection:'column',
    gap:'30px',
    maxWidth:'562px',
    minWidth:'400px',
    '@media (max-width:768px)':{
        minWidth:'100%',
    }

}

export const directorSec = {
    display:'flex',
    gap:'98px',
    '@media (max-width:992px)':{gap:'30px'},
    '@media (max-width:768px)':{flexDirection:'column', alignItems:'center'},
}

export const directorHead = {
    textAlign:'center',
    padding:'0 0 50px 0',
    '@media (max-width:768px)':{
        fontSize:'40px'
    },
    '@media (max-width:600px)':{
        fontSize:'24px'
    }
}