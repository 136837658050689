import { Box, Typography } from '@mui/material'
import React from 'react'
import { Images } from '../../../asset/images'
import { directorHead, directorPara, directorRight, directorSec } from './director_style'

function Director() {
  return (
    <>
        <Box>
            <Box sx={{padding:{md:'70px 4.5rem 70px 4.5rem', sm:'30px 2rem 30px 2rem',xs:'30px 20px 30px 20px'},maxWidth:'1296px',margin:'0 auto'}}>


                <Typography variant='h2' sx={directorHead} >The Genesis of HOW</Typography>

                <Box sx={directorSec}>


                    <Box sx={{maxWidth:'600px'}}>
                        <img src={Images.DirectorImg} style={{maxWidth:'100%'}}/>

                        <Box>
                        <Typography sx={{fontWeight:'700',color:'rgba(63, 23, 182, 1)',fontSize:'24px'}}>Usha Guru</Typography>
                        <Typography sx={{fontSize:'16px',fontWeight:'700'}}>
                        Director & Business Head
                        </Typography>
                    </Box>

                    </Box>

                 

                    <Box sx={{display:'flex',alignItems:'center'}} >

                        <Box sx={directorRight}>


                            <Typography sx={directorPara}>
                            Our CEO, an avid online shopper frequently hunting for new masterpieces, often found her excitement dwindling when her favorite brands were available online but fell short of expectations with lackluster websites or outdated designs. 
                            </Typography>                       
                                
                            <Typography sx={{fontSize:{md:'24px',sm:'20px'},lineHeight:{md:'40px'}}}>

                                <Box>
                                    <img src={Images.DirectorComma}/>
                                </Box>

                            This was a game-changer for her, instilling the belief that all brands should have their online presence as a central pillar of their business strategy.
                            </Typography>
                                
                            <Typography sx={directorPara}>
                            Inspired by this vision, she founded <span style={{color:'rgba(63, 23, 182, 1)',fontWeight:'700'}}>“House of Websites”</span> designed to create exceptional digital footprints, especially for businesses.
                            </Typography>


                        </Box>

                    </Box>


                </Box>
            </Box>
        </Box>
    </>
  )
}

export default Director
