import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { blueGradient, ConstInput, contactBtn, contactButton, contactErrorStyle, contactField, contactLeft, contactRight, ContactRightHead, contactRightInner, ContactsInfo, ContactStatus, contactStyle, ContactTeam, ContactText, greenGradient, styleModel } from './contact_style'
import { contactStatus, contactTeamInfo } from '../../../constants'
import { Images } from '../../../asset/images'
import apiClient from '../../../component/api/apiClient'
import { apiRouters } from '../../../component/api/apiRouters'
import { ErrorHandler } from './formErrorHandler'

import ReCAPTCHA from 'react-google-recaptcha'
import Progress from '../../../component/UI-KIT/progressBar'

import WarningIcon from '@mui/icons-material/Warning';

function ContactMain() {

  // useEffect(()=>{
        
  //     const fetchData = async () => {
  //         try {
  //           const response1 = await apiClient.get(apiRouters.contactHeadInfo);
  //           // const response2 = await apiClient.get(apiRouters.contactLogo);
  //           console.log(response1.data);
  //           // console.log(response2.data);
            

  //         } catch (e) {
  //           console.log(e);
  //         }
  //       };
  //       fetchData();
    

  // },[])
    
  const [submitModel,setSubmitModel] = useState(false);

  const [openBack,setOpenBack] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [nameError, setNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [infoError, setInfoError] = useState(false);
  // const [captchaError,setCaptchaError] = useState(false);


  const [contactForm, setContactForm] = useState({
    fullName: '',
    emailAddress: '',
    aboutProject: '',
  })

  //handlechange function to handle the form change

  const handleChange = (e) => {
    let { name, value } = e.target;

    setContactForm(prev => ({
      ...prev, [name]: value
    }))

    ErrorHandler(setNameError, setMailError, setInfoError, contactForm);

  }


  //postcontact function for post api 
  const postContact = async () => {

    const check = ErrorHandler(setNameError, setMailError, setInfoError, contactForm);
    // if(!captchaError){

    // }
    if (check != 3) {
      return;
    }
    try {
      setOpenBack(true);
      const response = await apiClient.post(apiRouters.contactPost, contactForm);

      if (response.status == 201) {
        setOpenBack(false);
        handleOpen();
        setTimeout(() => { handleClose() }, 3000)
        // console.log(response.data);
      }
      else{
        setOpenBack(false);
        handleOpen();
        setSubmitModel(true);
        setTimeout(() => { handleClose() }, 3000)
      }

    }
    catch (e) {
      console.log(e);
      setOpenBack(false);
      handleOpen();
      setSubmitModel(true);
      setTimeout(() => { handleClose() }, 3000)

    }
  }

  // const handleCaptchaChange = (value) => {
  //   // setCaptchaError(false);
  // }
  // const siteKey = process.env.REACT_APP_LOCALHOST_CAPTCHA_TOKEN;

  return (
    <>
      <Box sx={blueGradient}>

        <Box sx={greenGradient}>
          <Box sx={contactStyle}>

            <Box sx={contactLeft} >

              <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly ',
                padding: '40px 0 0 0',
                '@media (max-width:600px)': { padding: '15px 20px' },
                flexWrap: 'wrap',

              }}>
                {contactTeamInfo.map((data) => (
                  <ContactTeam>
                    <Box sx={{ position: 'relative', width: '75px' }}>
                      <img src={data.image} style={{ borderRadius: '50%', maxWidth: '100%' }} />
                      <a style={{ position: 'absolute', bottom: '-10px', left: '35%' }} href={data.link} target='_blank'>
                        <img src={Images.ContactIn} />
                      </a>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                      {data.name}
                    </Box>
                    <Box sx={{ fontSize: '14px', fontWeight: '300' }}>
                      {data.role}
                    </Box>
                  </ContactTeam>))}
              </Box>


              <Box sx={{ padding: '25px 0px', fontWeight: '500' }}>

                {contactStatus.map((data) => (
                  <ContactStatus>
                    <Box>
                      <img src={Images.ContactSVG} style={{ paddingRight: '12px' }} />
                    </Box>
                    <Box>
                      {data.detail}
                    </Box>
                  </ContactStatus>
                ))}

              </Box>



              <Box sx={{ padding: '25px 0 48px 0px', }}>

                <Box sx={{ fontWeight: '350' }}>Project Inquires</Box>

                <Box sx={ContactsInfo}>

                  <img src={Images.ContactMail} />
                  <a href='mailto:info@houseofwebsites.com'>info@houseofwebsites.com</a>

                </Box >

                <Box sx={ContactsInfo}>

                  <img src={Images.ContactPhone} />
                  <a href='tel:8925931376' style={{ borderBottom: '1px solid' }}>Book a call</a>

                </Box>

              </Box>


            </Box>





            <Box sx={contactRight}>

              <Box sx={contactRightInner}>



                <Typography variant='h4' sx={ContactRightHead}>Tell us about your project</Typography>
                <Box sx={contactField}>
                  <Box sx={ContactText}>
                    <TextField id="standard-basic" label="Full Name" variant="standard" sx={ConstInput} onChange={handleChange} name='fullName' />

                    {nameError && <Typography sx={contactErrorStyle}>Please Fill the Name</Typography>}
                  </Box>
                  <Box sx={ContactText}>
                    <TextField id="standard-basic" label="Email Address" variant="standard" sx={ConstInput} onChange={handleChange} name='emailAddress' />
                    {mailError && <Typography sx={contactErrorStyle}>Please Fill the Valid Mail</Typography>}
                  </Box>
                </Box>
                <Box >
                  <TextField id="standard-basic" label="About Project" variant="standard" sx={ConstInput} onChange={handleChange} name='aboutProject' />
                  {infoError && <Typography sx={contactErrorStyle}>Please Tell us About Project</Typography>}
                </Box>

                {/* <div>
                  <div style={{ marginTop: '2rem' }}>

                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <p>
                    {captchaError && <Typography sx={contactErrorStyle}>Please Fill the Captcha</Typography>}
                  </p>
                </div> */}

                <Box sx={contactBtn}>

                  <Box sx={{
                    color: 'rgba(112, 114, 113, 1)', maxWidth: '228px', fontSize: '12px', fontWeight: '500', '@media(max-width:768px)': { textAlign: 'center' }, lineHeight: '18px', 'a': {
                      textDecoration: 'none !important', color: '#000'
                    }
                  }}>
                    By submitting this form you agree to our <a href=''>Terms of Use</a> and <a href=''>Privacy Policy</a>
                  </Box>

                  <Box>


                    <Box sx={contactButton} onClick={postContact}>
                      <Button sx={contactButton.contactFirst}>
                        <img src={Images.ContactArrow} />
                      </Button>
                      <Button sx={contactButton.contactSecond}>
                        Submit
                      </Button>
                    </Box>

                  </Box>
                </Box>

                {/* form submitted model  */}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModel}>
                    <Box></Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      {submitModel ? <WarningIcon/> : <img src={Images.SuccessIcon} /> }
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2,fontFamily:'Lufga' }}>
                      {submitModel ? 'Error to Submit Form' : 'Form Submitted Successfully'}
                    </Typography>
                  </Box>
                </Modal>

                {/* for backdrop functionality */}
                <Progress openBack={openBack}/>



              </Box>

            </Box>



          </Box>


        </Box>




      </Box>

    </>
  )
}

export default ContactMain