import { Box, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ChennaiTeam from './chennaiTeam'
import CuddaloreTeam from './cuddaloreTeam'
import { tabStyle } from './teamGroup_style'

function GroupSlider() {
    const [value, setValue] = useState('1')

    const CustomTab = styled(Tab)(({ theme }) => ({
        '&.Mui-selected': {
            color: 'rgba(254, 198, 29, 1)',
        },
        color: '#fff',
        textTransform: 'capitalize',
        letterSpacing: '1px',
        fontFamily:'Lufga',
    }))

    const CustomTabList = styled(TabList)(({ theme }) => ({
        '& .MuiTabs-indicator': {
            backgroundColor: 'rgba(254, 198, 29, 1)',
        },
    }))

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    return (
        <>
            <Box
                sx={{
                    maxWidth: '1400px',
                    margin: '0 auto',
                    padding: {md:'0 4.5rem',xs:'0 2rem'},
                    '@media (max-width:768px)':{
                        padding:'25px 20px',
                      }
                    
                }}
            >
                <Box
                    sx={{
                        paddingTop: '50px',
                        color: '#fff',
                        maxWidth: '1000px',
                        textAlign: 'center',
                        margin: '0 auto',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: '300',
                            fontSize: '30px',
                            fontFamily: 'Lufga',
                            '@media (max-width:768px)': { fontSize: '20px' },
                            '@media (max-width:500px)': { fontSize: '18px' },
                        }}
                    >
                        Merging the creative brilliance of Chennai and
                        Cuddalore, the HOW Crew stands as one though miles
                        apart. They weave epic narratives into every project,
                        transforming business ideas into vibrant digital
                        realities.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ width: '100%', typography: 'body1', color: '#fff' }}>
                <TabContext
                    value={value}
                    sx={{ color: '#fff', overflow: 'hidden' }}
                >
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingBottom: '50px',
                        }}
                    >
                        <CustomTabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            sx={{
                                borderBottom: '1px solid rgba(88, 88, 88, 1)',
                            }}
                        >
                            <CustomTab
                                label="Cuddalore Team"
                                value="1"
                                sx={tabStyle}
                            ></CustomTab>
                            <CustomTab
                                label="Chennai Team"
                                value="2"
                                sx={tabStyle}
                            ></CustomTab>
                        </CustomTabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: '0' }}>
                        <CuddaloreTeam />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: '0' }}>
                        <ChennaiTeam />
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    )
}

export default GroupSlider
