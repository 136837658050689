import React from 'react';
import { Grid, Box, Typography, Item, Button } from '@mui/material';
import { Images } from '../../../asset/images';
import { ContactSec, ContactSecWord } from '../work.style';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes'
const Index = () => {
  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={5}  order={{ xs: 15, sm: 15, md:15, lg:15}}>
        <ContactSec variant="h2" sx={{ textAlign: "left", padding: { xs: '0 1.25rem', sm: '0 2rem', md:'0', lg:'0'}, marginTop:'40px'  }}>
          Take a Look 
          at What
          <img src={Images.Line} alt='line' style={{ width: "35%" }} />
        </ContactSec>
      </Grid>
      
        <Grid item xs={12} sm={12} md={4} lg={2}  order={{ xs: 16, sm: 16, md:16, lg:16}}>
          <Box sx={{ marginTop:'40px', textAlign:'center' }}>
            {/* <img
            className='cantactBG'
              src={Images.WorkContact}
              alt="selectwork"
              style={{ width: '100%' }}
            /> */}
            {/* <Box className="contactWord"> */}
             <Link to={routes.CONTACTUS}>
              <Button className='contactButton'>
                 <ContactSecWord variant="body2" sx={{textTransform:'none'}} >
                 Contact us
              </ContactSecWord>
              </Button>
              </Link>
            {/* </Box> */}
          </Box>
        </Grid>
      
      <Grid item xs={12} sm={12} md={4} lg={5}  order={{ xs: 17, sm: 17, md:17, lg:17}}>
        <ContactSec variant="h2" sx={{ padding: { xs: '0 1.25rem', sm: '0 2rem', md: "5.9rem  0 0", lg: "5.9rem 0 0 0" }, textAlign: "right", marginTop:'40px' }} >
          We Can Do<br />
          <img src={Images.Line} alt='line' style={{ width: "35%" }} />
          For You
        </ContactSec>
      </Grid>

    </>
  );
}

export default Index;
