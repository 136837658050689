import styled from '@emotion/styled'
import { Typography } from '@mui/material'



export const WorkHeading = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(0.875rem, 6vw, 5rem)',
    fontWeight: '700',
    // marginTop: '10px',
    letterSpacing: '3px',
    
}))

export const WorkHeaderColor = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(0.875rem, 2vw, 1.25rem)',
    background: '#fff',
    fontFamily:'lufga',
    lineHeight: 'clamp(20px, 2.1vw, 26.1px)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
    fontWeight: 400,
    marginTop: '19px',
    // letterSpacing: '1px',
}))

export const WorkHelloDreamer = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(32px, 4vw, 64px)',
    color: '#fff',
    fontFamily:'Dystopian',
    lineHeight: 'clamp(38.4px, 4vw, 76.8px)',
    fontWeight: 700,
    paddingTop: 'clamp(16.75rem, 25vw, 23.5rem)',
    letterSpacing: '1px',
}))

export const WorkHelloDreamerPtag = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(12px, 4vw, 24px)',
    fontFamily:'lufga, sans-serif',
    lineHeight: 'clamp(23px, 4vw, 38px)',
    color: '#fff',
    fontWeight: 300,
    marginTop: '19px',
    letterSpacing: '1px',
    paddingBottom:'81px',
}))

export const SelectedworkHeading = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(20px, 4vw, 40px)',
    color: '#000',
    fontFamily:'lufga',
    lineHeight: 'clamp(31.6px, 4vw, 63.21cpx)',
    // paddingTop: '65px',
    fontWeight: 700,
    letterSpacing: '1px',
}))

export const SelectedworkPtag = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(8.69px, 4vw, 17.38px)',
    fontFamily:'lufga',
    lineHeight: 'clamp(20px, 2vw, 25.28px)',
    color: '#333',
    fontWeight: 400,
    marginTop: '11.85px',
    letterSpacing: '1px',
    // paddingBottom:'78.5px',
}))

export const SelectedworkPtagRight = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(8.69px, 4vw, 17.38px)',
    fontFamily:'lufga',
    lineHeight: 'clamp(28px, 2vw, 28.44px)',
    color: '#212121',
    fontWeight: 300,
    marginTop: '27.32px',
    letterSpacing: '1px',
    paddingBottom:'40px',
}))

export const SelectedworkPtagRightHastag = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(7px, 4vw, 14px)',
    fontFamily:'lufga',
    lineHeight: 'clamp(11.85px, 2vw, 23.7px)',
    color: '#000',
    fontWeight: 400,
    letterSpacing: '1px',
    border:"0.79px solid #A4A4A4",
    borderRadius:'30px',
    padding:"11.85px 0px",
    width:'100%',
    textAlign:'center',
}))

export const ContactSec = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(1.5rem, 4vw, 5rem)',
    fontFamily:'Dystopian',
    lineHeight: 'clamp(2.5rem, 4vw, 5.43rem)',
    color: '#000',
    fontWeight: 700,
    letterSpacing: '1px',
    // padding:"11.85px 40px",
}))
export const ContactSecWord = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(0.875rem, 2.5vw, 1.481rem)',
    fontFamily:'lufga',
    lineHeight: '1.975rem',
    color: '#000',
    fontWeight: 700,
    letterSpacing: '1px',
}))

export const TechStack = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    fontFamily:'lufga',
    lineHeight: '18.27px',
    color: '#000000',
    fontWeight: 400,
    marginTop: '30px',
    // paddingBottom:'40px',
    fontStyle: "italic",
    display:'flex',

}))