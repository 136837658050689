import { Box, Typography } from '@mui/material'
import React from 'react'
import { AppContainer } from '../career.style'
import Glassdoorslider from './glassdoorslider'
import {
    ChildItem,
    DashbrdNum,
    DashbrdTitle,
    GridContainer,
} from './glassdoor.style'
import CountUp from 'react-countup'
import { countData } from '../../../constants'
import { useInView } from 'react-intersection-observer'

function Glassdoor() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.5, // Trigger when 50% of the component is in view
    })
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: '#3F17B6',
                    position: 'relative',
                    py: {
                        xl: '96px',
                        lg: '96px',
                        md: '50px',
                        sm: '40px',
                        xs: '35px',
                    },
                    paddingBottom: {
                        xl: '96px',
                        lg: '96px',
                        md: '50px',
                        sm: '60px',
                        xs: '85px',
                    },
                }}
            >
                <AppContainer>
                    <Typography
                        sx={{
                            fontSize: {
                                xl: 64,
                                lg: 64,
                                md: 40,
                                sm: 45,
                                xs: 35,
                            },
                            fontFamily: 'Dystopian',
                            fontWeight: '700',
                            color: '#fff',
                            textAlign: { sm: 'left', xs: 'center' },
                        }}
                        data-aos="fade-right"
                        data-aos-duration="1400"
                    >
                        No Glassdoor
                    </Typography>
                    <Glassdoorslider />
                </AppContainer>
            </Box>
            <Box sx={{ background: '#070707', width: '100%', height: 'auto' }}>
                <AppContainer>
                    <GridContainer>
                        {countData.map((item, index) => (
                            <ChildItem
                                ref={ref}
                                className={item.class}
                                data-aos="zoom-in"
                                data-aos-delay={item.index * 100}
                                data-aos-duration={item.fade_time}
                                key={index}
                                index={index}
                            >
                                <DashbrdTitle variant="h4">
                                    {item.projectName}
                                </DashbrdTitle>
                                <DashbrdNum>
                                    {inView && (
                                        <CountUp
                                            start={item.start}
                                            end={item.end}
                                            duration={5}
                                            separator=","
                                            formattingFn={(value) =>
                                                item.projectName === 'Location'
                                                    ? value
                                                          .toString()
                                                          .padStart(2, '0') 
                                                    : value
                                            }
                                        />
                                    )}

                                    {item.add}
                                </DashbrdNum>
                            </ChildItem>
                        ))}
                    </GridContainer>
                </AppContainer>
            </Box>
        </Box>
    )
}

export default Glassdoor
