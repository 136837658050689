import { createContext, useContext, useReducer } from "react"


const initialState={
    addtocard:'',
    purchase:'',
    setState:()=>{},
    setAddtocard:()=>{},
    setPurchase:()=>{}
}

const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_ADDTOCARD': {
        return { ...state, addtocard: action.payload }
      }
      default: {
        throw new Error(`Unknown type: ${action.type}`)
      }
    }
}

export const AppContext = createContext(initialState)

const AppProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
  
    const handleAddtoCardType = (data) => {
      dispatch({ type: 'SET_ADDTOCARD', payload: data })
    }
    let data = {
        ...state,
        setAddtocard : handleAddtoCardType,
    }
    return <AppContext.Provider value={data}>{props.children}</AppContext.Provider>
}

const useAppContext = () => useContext(AppContext)

export { AppProvider, useAppContext }





