import styled from '@emotion/styled';
import { Images } from '../../asset/images'

export const BannerSection = styled('div')(({ theme }) => ({
    position: 'relative',    
    // background: `url(${Images.CareerBanner})` ,
//    backgroundSize: 'cover',
//    backgroundPosition: 'center',
//     backgroundRepeat: 'no-repeat',
   height: '1020px',
   overflow: 'hidden',
   [theme.breakpoints.down('lg')]: {
    height: '920px'
},
   [theme.breakpoints.down('md')]: {
      height: '810px'
},
[theme.breakpoints.down('sm')]: {
    height: '530px'
},
[theme.breakpoints.down('xs')]: {
    height: '515px'
}
//    '@media (max-width:992px)':{
//     height: '930px',
//     },
//     '@media (max-width:768px)':{
//     height: '810px',
//     },
//     '@media (max-width:600px)':{
//     height: '730px',
//     },
//     '@media (max-width:360px)':{
//     height: '515px',
//     }
}))

export const AppContainer = styled("div")(({ theme }) => ({
    maxWidth: '1296px',    
    margin: '0 auto',
   padding:'0 4rem',
   '@media (min-width: 992px) and (max-width: 1192px)': {
    padding:'0 4rem',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
    padding:'0 2rem',
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
    padding:'0 1.25rem',
    },
    '@media (min-width: 320px) and (max-width: 599px)': {
    padding:'0 1.25rem',
    },
    
//    [theme.breakpoints.down('sm')]: {
//     padding: '0 1.25rem',
    
//    },
//    [theme.breakpoints.down('xs')]: {
//     padding: '0 20px',
    
//    },
}))
export const StyledGradient = styled("span")(({ theme }) => ({
    background: 'linear-gradient(80.32deg, #F83A3A 10%, #F13DD4 50%, #F83A3A 80%)',    
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
}))
export const HorizontalLine = styled("span")(({ theme }) => ({
    width: '54px',
    height: '9px',
    background: '#fff',
    margin: '27px 0',
    marginRight: '15px',
    display: 'inline-block',
    '@media (max-width: 900px)': {
        margin: '16px 15px 16px 0',
        width: '45px',
        height: '6px',
        
    },
    '@media (max-width: 600px)': {
        margin: '8px 15px 8px 0',
        width: '35px',
        height: '4px',
       
    },
}))
export const JoinBtn = styled('Button')(({ theme }) => ({
    maxWidth: '150px', // Corrected the semicolon to a comma
    backgroundColor: '#00e5fd',
    borderRadius: '50px',
    fontSize: '16px',
    fontFamily: 'Lufga',
    fontWeight: '700',
    color: '#000',
    textTransform: 'capitalize',
    overflow: 'hidden',
    zIndex: 1,
    position: 'relative',
    padding: '12px 20px',
    border: 'none',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: 0,
      borderRadius: '15px',
      backgroundColor: '#fff',
      zIndex: -1,
      transition: 'all 250ms',
    },
    '&:hover': {
      color: '#000',
      '&:before': {
        width: '100%',
      },
    },
}))
