import { Box, Typography } from '@mui/material'
import React from 'react'
import { LandingHeaderColor, LandingHeading } from '../../landing/landing.style'
import { Images } from '../../../asset/images'
import {
    AboutBannerTitle,
    AboutisDescription,
    AboutusBannerMainOrder,
    AboutusBannerStyle,
    AboutusBannerTitleStyle,
    MainBanner,
} from './aboutus-style'

const AboutusBanner = () => {
    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',

                background: {
                    md: `linear-gradient(
                    110deg,
                    rgba(8, 0, 139, 1),
                    rgba(0, 0, 255, 0) 50.71%
                  ),
                  linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 1),
                    rgba(0, 0, 0, 1) 50.71%
                  )`,
                    xs: `linear-gradient(
                360deg,
                rgba(8, 0, 139, 0.6),
                rgba(0, 0, 255, 0) 40.71%
              ),
              linear-gradient(
                90deg,
                rgba(0, 0, 0, 1),
                rgba(0, 0, 0, 1) 50.71%
              )`,
                },
            }}
        >
            {/* <Box
                component="img"
                src={Images.BlueBanner}
                alt="banner"
                width={'100%'}
            /> */}

            <Box>
                {/* <img style={{top:'0',position:'absolute', width: '100%'}} src={Images.BlueBanner}/> */}
            </Box>
            <AboutusBannerMainOrder>
                <MainBanner>
                    <div>
                        <AboutBannerTitle variant="h1">About </AboutBannerTitle>{' '}
                        &nbsp;
                        <AboutusBannerTitleStyle variant="h1">
                            How
                        </AboutusBannerTitleStyle>
                    </div>
                    <AboutisDescription>
                        House of Websites blends creative flair with
                        technological expertise to transform business ideas into
                        dynamic online experiences. Our dedication to quality
                        and innovation drives us to push boundaries and create
                        standout digital solutions.
                    </AboutisDescription>
                    <AboutisDescription>
                        With a commitment to excellence and a passion for what
                        we do, we consistently deliver exceptional results and
                        superior service. At House of Websites, we turn your
                        ideas into powerful online presences.
                    </AboutisDescription>
                </MainBanner>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={Images.GlobalAnimation}
                        alt="banner"
                        sx={{
                            height: {
                                xs: '280px',
                                sm: '400px',
                                lg: 'auto',
                            },
                        }}
                    />
                </Box>
            </AboutusBannerMainOrder>
        </Box>
    )
}

export default AboutusBanner
